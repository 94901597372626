import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import apiurl from '../../../apiurl'
import UploadedAdapter from './UploadedAdapter/UploadedAdapter'

const Uploaded = () => {
  const [search, setsearch] = useState('')
  const [oarr, setoarr] = useState([])
  const [uploaded, setuploaded] = useState([])
  const loadData = ()=>{
    axios.get(`${apiurl}/video`).then((res)=>{
      if(res.status === 200){
        console.log(res.data.videos)
        setuploaded(res.data.videos)
        setoarr(res.data.videos)
      }
    }).catch((err)=> console.log(err))
  }
  useEffect(() => {
    if(search){
    setoarr(uploaded.filter((v)=> String(v.title).toLowerCase().trim().includes(search.toLowerCase().trim())))
    }
    else{
      setoarr(uploaded)
    }
  }, [search])
  useEffect(() => {
    loadData()
  }, [])
  
  
  
  return (
    <div>
      <TextField
        label="Search"
        value={search}
        onChange={e=> setsearch(e.target.value)}
        style={{width:'80%',margin:10}}
      />
      <div className='grid md:grid-cols-3 grid-cols-1'>
        {oarr.map((v,i)=> <UploadedAdapter loadData={loadData} data={v} key={i}/>)}
      </div>
    </div>
  )
}

export default Uploaded