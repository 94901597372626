import axios from "axios";
import React, { useState, useContext } from "react";
import { Paper, TextField, Button } from "@mui/material";
import { AuthContext } from "../../hooks/AuthContext";
import apiurl from "../../apiurl";

const Login = ({ setlogged }) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const { dispatch } = useContext(AuthContext);

  const loginUser = async () => {
    try {
      const response = await axios.post(`${apiurl}/auth/login`, {
        email,
        password,
      });

      const userData = response.data.data;

      if (userData.role === "admin" || userData.role === "moderator") {
        alert("Login successful!");
        dispatch({ type: "LOGIN_SUCCESS", payload: userData });
        setlogged(true);
      } else {
        alert(
          "Access denied. Only admin and moderator users can access the admin panel."
        );
        console.log(
          "Access denied. Only admin and moderator users can access the admin panel."
        );
        setemail("");
        setpassword("");
      }
    } catch (error) {
      console.log("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-sky-100 flex items-center justify-center flex-col">
      <Paper variant="outlined" className="p-3">
        <h1 className="uppercase text-blue-700 text-2xl text-center my-3">
          admin login
        </h1>
        <TextField
          label="E-mail"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        <TextField
          label="Password"
          style={{ marginTop: 10 }}
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          variant="outlined"
          inputMode="password"
          type="password"
          fullWidth
        />
        <center>
          <Button
            onClick={loginUser}
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            disabled={!email || !password}
          >
            Login
          </Button>
        </center>
      </Paper>
    </div>
  );
};

export default Login;
