import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import apiurl from "../../../apiurl";

const QuotationAdapter = ({ v, loadData }) => {
  const [clean, setclean] = useState(false);
  const [info, setinfo] = useState(false);
  const [del, setdel] = useState(false);

  const deleteQuo = () => {
    axios
      .delete(`${apiurl}/periodicForm/${v.id}`)
      .then((res) => {
        if (res.status === 200) {
          loadData();
          setdel(false);
        }
      })
      .catch((err) => {
        alert("Something unexpected happened");
        console.log(err);
      });
  };

  console.log("Hi", v.services);

  return (
    <div className="p-2">
      <Dialog
        open={del}
        onClose={(e) => setdel(false)}
        aria-labelledby="Delete Quotation"
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained" aria-label="">
            <Button color="error" onClick={deleteQuo}>
              Delete
            </Button>
            <Button color="primary" onClick={(e) => setdel(false)}>
              Cancel
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
      <p className="my-2 text-2xl uppercase text-blue-700 font-bold">
        Contact Information
      </p>
      <p className="my-1 text-blue-600">
        First Name: <span className="text-gray-600">{v.firstName}</span>
      </p>
      <p className="my-1 text-blue-600">
        Last Name: <span className="text-gray-600">{v.lastName}</span>
      </p>
      <p className="my-1 text-blue-600">
        Phone: <span className="text-gray-600">{v.phoneNumber}</span>
      </p>
      <p className="my-1 text-blue-600">
        E-mail: <span className="text-gray-600">{v.email}</span>
      </p>
      <center>
        <ButtonGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => setinfo(!info)}
          >
            {info ? "Hide" : "Show"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={(e) => setdel(true)}
          >
            Delete
          </Button>
        </ButtonGroup>
      </center>
      {info ? (
        <>
          <p className="my-1 text-blue-600">
            Address: <span className="text-gray-600">{v.streetAddress}</span>
          </p>
          <p className="my-1 text-blue-600">
            Address 2: <span className="text-gray-600">{v.addressLine2}</span>
          </p>
          <p className="my-1 text-blue-600">
            City: <span className="text-gray-600">{v.city}</span>
          </p>
          <p className="my-1 text-blue-600">
            ZIP: <span className="text-gray-600">{v.postalCode}</span>
          </p>
          <p className="my-1 text-blue-600">
            Country: <span className="text-gray-600">{v.country}</span>
          </p>

          <p className="text-2xl my-2 uppercase text-blue-700 font-bold">
            Boat Information
          </p>
          <p className="my-1 text-blue-600">
            Boat Name: <span className="text-gray-600">{v.boatName}</span>
          </p>
          <p className="my-1 text-blue-600">
            Boat Manufacturer:{" "}
            <span className="text-gray-600">{v.boatManufacturer}</span>
          </p>
          <p className="my-1 text-blue-600">
            Boat Year: <span className="text-gray-600">{v.year}</span>
          </p>
          <p className="my-1 text-blue-600">
            Boat Location:{" "}
            <span className="text-gray-600">{v.registryCountry}</span>
          </p>
          <p className="my-1 text-blue-600">
            Hull: <span className="text-gray-600">{v.hullId}</span>
          </p>

          <p className="text-2xl my-2 text-blue-700 uppercase font-bold">
            Engine Information
          </p>
          <p className="my-1 text-blue-600">
            Engine Manufacturer:{" "}
            <span className="text-gray-600">{v.engineManufacturer}</span>
          </p>
          <p className="my-1 text-blue-600">
            PORT: <span className="text-gray-600">{v.engineHours}</span>
          </p>
          <p className="my-1 text-blue-600">
            Service Details:{" "}
            <span className="text-gray-600">{v.serviceDetails}</span>
          </p>
          <p className="my-1 text-blue-600">
            Current Boat Location:{" "}
            <span className="text-gray-600">{v.currentLocation}</span>
          </p>
          <p className="text-black my-2 text-lg">Detail: {v.details}</p>

          <div className="mt-3">
            <p className="text-center uppercase text-2xl text-blue-800 mb-3">
              Services
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setclean(!clean)}
            >
              {clean ? "Hide" : "Show"}
            </Button>
            {clean ? (
              <div className="flex flex-wrap mt-4">
                {Object.values(v.services).map((val, i) => {
                  const { service, selectedOption } = val;
                  return (
                    <div
                      key={i}
                      className="bg-blue-100 border border-blue-400 text-blue-700 px-3 py-2 rounded-lg mr-2 mb-2"
                    >
                      {service}
                      {selectedOption && ` (${selectedOption})`}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default QuotationAdapter;