import React, { useEffect, useState } from 'react'
import axios from 'axios'
import apiurl from '../../apiurl'
import { Button, Paper, TextField, ButtonGroup } from '@mui/material'
import QuotationAdapter from './QuotationAdapter/QuotationAdapter'
import excelfile from 'export-from-json'
import {Download, Refresh} from '@mui/icons-material'


const QuotationRequest = () => {
  const [quote, setquote] = useState([])
  const [oarr, setoarr] = useState([])
  const [filter, setfilter] = useState('')

  const loadData = ()=>{
    axios.get(`${apiurl}/periodicForm`,{
    }).then((res)=>{
      setquote(Object.values(res.data.response.data).reverse())
    }).catch(err => console.log(err))
  }
  
  useEffect(() => {
    loadData()
  }, [])
  
  useEffect(() => {
    setoarr(quote)
  }, [quote])

  const filterData = (e)=>{
    setfilter(e)
    setoarr(quote.filter((v,i)=> v.firstName.includes(e) || v.email.includes(e) || v.phoneNumber.includes(e) ))
  }
  const exportinxl = ()=>{
    const data = Object.assign(oarr,{})
    const fileName = `quotation-${new Date().getTime()}`
    const exportType =  excelfile.types.xls
    excelfile({data,fileName,exportType})
  }
  
  return (
    <div>

        <div className='p-3 flex md:flex-row flex-col mt-2'>
          <div className='flex'>
          <TextField
            label="Search"
            fullWidth
            value={filter}
            onChange={(e)=>filterData(e.target.value)}
            variant="outlined"
          />
          <Button variant="contained" style={{marginLeft:10}} color="primary" disabled={!filter} onClick={e=> {setfilter(''); setoarr(quote)}}>
            Reset
          </Button>
          </div>
          <div className='p-3'>
            <ButtonGroup>
            <Button variant="contained" color="primary" disabled={oarr.length<1} onClick={exportinxl}>
             <Download/> Export In Excel
          </Button>
          <Button variant="contained" color="primary" onClick={loadData}>
            <Refresh/> Reload
          </Button>
            </ButtonGroup>
        
        </div>
        </div>

      <div>
        {oarr.map((v,i)=> <Paper style={{marginTop:10}}>
          <QuotationAdapter v={v} loadData={loadData}/>
        </Paper>)}
      </div>
    </div>
  )
}

export default QuotationRequest