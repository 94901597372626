import axios from 'axios'
import React, { useEffect, useState } from 'react'
import apiurl from '../../apiurl'
import ContactAdapter from './ContactAdapter/ContactAdapter'
import app from '../../firebase'
import excelfile from 'export-from-json'
import { Download, Refresh } from '@mui/icons-material'
import { Button, ButtonGroup, TextField } from '@mui/material'
const Contact = () => {
  const [contarr, setcontarr] = useState([])
  const [oarr, setoarr] = useState([])
  const [filter, setfilter] = useState('')

  const geteData = ()=>{
    axios.get(`${apiurl}/contactForm/`,{
    }).then((res)=>{
      setcontarr(res.data.response.data.reverse())
    }).catch((err)=> console.log(err))
  }
  useEffect(() => {
    setoarr(contarr)
  }, [contarr])
  
  useEffect(() => {
    geteData()
  }, [])
  const filterData = (e)=>{
    setfilter(e)
    setoarr(contarr.filter((v,i)=> v.fullname.includes(e) || v.email.includes(e)))
  }

  const deleteData = (id)=>{
    axios.delete(`${apiurl}/contactForm/${id}`,{
    }).then((res)=>{
      if(res.status === 200){
        geteData()
      }else{
        alert('Failed to delete..')
      }
    }).catch((err)=>{
      console.log(err)
      alert('failed to delete')
    })
  }
  const exportinxl = ()=>{
    const data = Object.assign(contarr,{})
    const fileName = `contact-${new Date().getTime()}`
    const exportType =  excelfile.types.xls
    excelfile({data,fileName,exportType})
  }
  return (
    <div>
      <h1 className='text-center text-blue-600 uppercase'>Contact responses</h1>
      <div className='p-3 flex md:flex-row flex-col mt-2'>
          <div className='flex'>
          <TextField
            label="Search"
            fullWidth
            value={filter}
            onChange={(e)=>filterData(e.target.value)}
            variant="outlined"
          />
          <Button variant="contained" style={{marginLeft:10}} color="primary" disabled={!filter} onClick={e=> {setfilter(''); setoarr(contarr)}}>
            Reset
          </Button>
          </div>
          <div className='p-3'>
            <ButtonGroup>
            <Button variant="contained" color="primary" disabled={oarr.length<1} onClick={exportinxl}>
             <Download/> Export In Excel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Refresh/>}            
            onClick={geteData}
          >
            Refresh
          </Button>
            </ButtonGroup>
        
        </div>
        </div>
      {oarr.map((v,i)=>{
        return <ContactAdapter v={v} deleteData={deleteData} key={i}/>
      })}
    </div>
  )
}

export default Contact