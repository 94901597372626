import React, { useEffect, useState } from "react";
import axios from "axios";
import apiurl from "../../apiurl";
import { List, ListItem } from "@mui/material";
import {
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Download, Delete, Edit } from "@mui/icons-material";
import excelfile from "export-from-json";

const User = () => {
  const [users, setUsers] = useState([]);
  const [userFilter, setUserFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  // State for editing
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const getUsers = () => {
    axios
      .get(`${apiurl}/user`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.response.data);
          setUsers(res.data.response.data);
          setFilteredUsers(res.data.response.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUsers();
  }, []);

  const filterUsers = (filterText) => {
    setUserFilter(filterText);
    if (filterText === "") {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
          user.last_name.toLowerCase().includes(filterText.toLowerCase()) ||
          user.email.toLowerCase().includes(filterText.toLowerCase()) ||
          user.role.toLowerCase().includes(filterText.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  const exportToExcel = () => {
    const data = filteredUsers.map((user) => {
      return {
        "First Name": user.first_name,
        "Last Name": user.last_name,
        Email: user.email,
        Address: user.address,
        "Phone Number": user.phone_number,
        Role: user.role,
        "Created At": user.createdAt,
        "Updated At": user.updatedAt,
      };
    });

    const fileName = `users-${new Date().getTime()}`;
    const exportType = excelfile.types.xls;

    excelfile({ data, fileName, exportType });
  };

  const deleteUser = (user) => {
    axios
      .delete(`${apiurl}/user/${user.uuid}`)
      .then((res) => {
        console.log(res.status);
        if (res.status === 204) {
          console.log(res);
          alert("User deleted successfully");
          setDeleteOpen(false);
          getUsers();
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const openEditDialog = (user) => {
    setSelectedUser(user);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setAddress(user.address)
    setPhoneNumber(user.phone_number);
    setSelectedRole(user.role);
    setSelectedStatus(user.status);
    setEditOpen(true);
  };

  

  const saveEditedUser = () => {
    axios
      .put(`${apiurl}/user/${selectedUser.id}`, {
        first_name: firstName,
        last_name: lastName,
        email,
        address,
        phone_number: phoneNumber,
        role: selectedRole,
        status: selectedStatus,
      })

      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setEditOpen(false);
          getUsers();
          alert("User updated successfully");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <h1 className="text-center text-3xl text-blue-600 uppercase">Users</h1>
      <div className="flex items-center">
        <TextField
          label="Search"
          value={userFilter}
          onChange={(e) => filterUsers(e.target.value)}
          variant="outlined"
        />
        <Button
          variant="contained"
          onClick={exportToExcel}
          disabled={filteredUsers.length === 0}
        >
          <Download /> Export to Excel
        </Button>
      </div>
      <Paper>
        <List>
          {filteredUsers.map((user) => (
            <ListItem key={user.id}>
              <div className="shadow-gray-400 shadow-md w-full p-3">
                <p className="text-blue-600 my-1">
                  First Name:{" "}
                  <span className="text-gray-600">{user.first_name}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Last Name:{" "}
                  <span className="text-gray-600">{user.last_name}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Email: <span className="text-gray-600">{user.email}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Address: <span className="text-gray-600">{user.address}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Phone Number:{" "}
                  <span className="text-gray-600">{user.phone_number}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Role: <span className="text-gray-600">{user.role}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Active Status:{" "}
                  <span className="text-gray-600">
                    {user.status === 1 ? "Active" : "Inactive"}
                  </span>
                </p>

                <p className="text-blue-600 my-1">
                  Created At:{" "}
                  <span className="text-gray-600">{user.createdAt}</span>
                </p>
                <p className="text-blue-600 my-1">
                  Updated At:{" "}
                  <span className="text-gray-600">{user.updatedAt}</span>
                </p>
                <div>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => {
                      setSelectedUser(user);
                      setDeleteOpen(true);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() => openEditDialog(user)}
                  >
                    Edit User
                  </Button>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </Paper>
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={() => deleteUser(selectedUser)}>Delete</Button>
            <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Edit User Details</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
           <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            style={{ marginBottom: 20 }}
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel>Role</InputLabel>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="moderator">Moderator</MenuItem>
              <MenuItem value="client">Client</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={0}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={saveEditedUser}>Save</Button>
            <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default User;
