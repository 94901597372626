import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import apiurl from "../../apiurl";

const QuotationData = () => {
  const [servicesData, setServicesData] = useState({});
  const [categories, setCategories] = useState([]);
  const [editedCategories, setEditedCategories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/services`);
      const { servicesData } = response.data.response.data[0];
      setServicesData(servicesData);
      setCategories(Object.keys(servicesData));
      setEditedCategories(Object.keys(servicesData));
      setId(response.data.response.data[0].id);
    } catch (error) {
      setError("Error occurred while fetching data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryInputChange = (index, e) => {
    const updatedCategories = [...editedCategories];
    updatedCategories[index] = e.target.value;
    setEditedCategories(updatedCategories);
  };

  const handleCategoryBlur = (index) => {
    setCategories(editedCategories);
    const oldCategory = categories[index];
    const newCategory = editedCategories[index];
    if (oldCategory !== newCategory) {
      setServicesData((prevServicesData) => {
        const updatedServicesData = { ...prevServicesData };
        const categoryData = updatedServicesData[oldCategory];
        delete updatedServicesData[oldCategory];
        updatedServicesData[newCategory] = categoryData;
        return updatedServicesData;
      });
    }
  };

  const handleAddOption = (category) => {
    setServicesData((prevServicesData) => {
      const updatedCategory = [
        ...(prevServicesData[category] || []),
        { label: "", value: "" },
      ];
      return {
        ...prevServicesData,
        [category]: updatedCategory,
      };
    });
  };

  const handleRemoveOption = (category, index) => {
    setServicesData((prevServicesData) => {
      const updatedCategory = [...prevServicesData[category]];
      updatedCategory.splice(index, 1);
      return {
        ...prevServicesData,
        [category]: updatedCategory,
      };
    });
  };

  const handleOptionChange = (category, index, field, value) => {
    setServicesData((prevServicesData) => {
      const updatedCategory = prevServicesData[category].map((option, i) =>
        i === index ? { ...option, [field]: value } : option
      );

      return {
        ...prevServicesData,
        [category]: updatedCategory,
      };
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await axios.put(`${apiurl}/services/${id}`, {
        Headers: {
          "Content-type": "application/json",
        },
        servicesData,
      });
      if (response.status === 200) {
        console.log("Data saved successfully!");
        alert("Data saved successfully!");
      }
    } catch (error) {
      setError("Error occurred while saving data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {categories.map((category, index) => (
          <Grid item xs={12} md={6} lg={4} key={category}>
            <Box p={2} border={1} borderRadius={4}>
              <h1>{category}</h1>
              <TextField
                label="Category"
                value={editedCategories[index]}
                onChange={(e) => handleCategoryInputChange(index, e)}
                onBlur={() => handleCategoryBlur(index)}
                fullWidth
                margin="normal"
              />

              {servicesData[category] && (
                <div>
                  {servicesData[category].map((option, optionIndex) => (
                    <Box key={optionIndex} display="flex" alignItems="center" mb={1}>
                      <TextField
                        label="Label"
                        value={option.label}
                        onChange={(e) =>
                          handleOptionChange(category, optionIndex, "label", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Value"
                        value={option.value}
                        onChange={(e) =>
                          handleOptionChange(category, optionIndex, "value", e.target.value)
                        }
                        fullWidth
                        margin="normal"
                      />
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveOption(category, optionIndex)}
                        size="small"
                        style={{ marginLeft: "8px" }}
                      >
                        Remove
                      </Button>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleAddOption(category)}
                    size="small"
                  >
                    Add Option
                  </Button>
                </div>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default QuotationData;
