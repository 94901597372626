import axios from "axios";
import React, { useEffect, useState } from "react";
import apiurl from "../../../apiurl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Top = () => {
  const [hero, sethero] = useState("");
  const [herodesc, setherodesc] = useState("");
  // const [bg,setbg] = useState("");
  const [id, setid] = useState("");
  const loadData = () => {
    axios
      .get(`${apiurl}/companyDetails/`)
      .then((res) => {
        if (res.status === 200) {
          const obj = res.data.response.data[0];
          sethero(obj.heroTitle);
          setherodesc(obj.heroDescription);
          // setbg(obj.bgurl);
          setid(obj.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  const changeHero = () => {
    axios.put(`${apiurl}/companyDetails/${id}`, { heroTitle: hero, heroDescription: herodesc })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data)
          alert("changed!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1 className="text-center text-blue-700 text-3xl uppercase my-5">
        Landing Page
      </h1>
      <div>
        <h1 className="text-xl text-blue-600 my-3">Hero Text</h1>
        <TextField
          label="Text"
          value={hero}
          onChange={(e) => sethero(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <br />
        <h1 className="text-xl text-blue-600 my-3">Hero Description</h1>
        <TextField
          label="Description"
          value={herodesc}
          onChange={(e) => setherodesc(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={!hero}
          onClick={changeHero}
          style={{ marginTop: 10 }}
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default Top;
