import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBrPuFTiD2aOiSDEJd8KM6RIW2YxoHwS9E",
  authDomain: "byatch-services.firebaseapp.com",
  projectId: "byatch-services",
  storageBucket: "byatch-services.appspot.com",
  messagingSenderId: "144896434277",
  appId: "1:144896434277:web:49443da92e35ec3414a68c",
  measurementId: "G-2C6XTQC9C8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app