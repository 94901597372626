import React, {useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import apiurl from "../../../apiurl";

const UploadGallery = () => {
  const [galname, setgalname] = useState("");
  const [galsub, setgalsub] = useState("");
  const [galtumb, setgaltumb] = useState("");
  const [galelem, setgalelem] = useState([]);
  const [gpub, setGpub] = useState("public");

  const handleAddGalleryItem = () => {
    setgalelem([...galelem, { url: "", caption: "" }]);
  };

  const handleRemoveGalleryItem = (index) => {
    const updatedItems = [...galelem];
    updatedItems.splice(index, 1);
    setgalelem(updatedItems);
  };

  const handleGalleryItemChange = (index, field, value) => {
    const updatedItems = [...galelem];
    updatedItems[index][field] = value;
    setgalelem(updatedItems);
  };

  const createAlbum = () => {
    const elements = galelem.map((item) => {
      return {
        image: item.url,
        caption: item.caption,
      };
    });

    axios
      .post(`${apiurl}/gallery`, {
        title: galname,
        subtitle: galsub,
        privacy: gpub,
        thumbnailUrl: galtumb,
        elements: elements,
      })
      .then((res) => {
        alert("Created..");
        setgalname("");
        setgalsub("");
        setgaltumb("");
        setGpub("");
        setgalelem([]);
      })
      .catch((err) => {
        console.log(err);
        alert("There is a problem");
      });
  };

  return (
    <div className="p-2">
      <h1 className="text-center text-blue-600 uppercase text-xl font-bold">
        Create an album
      </h1>
      <div className="mt-3">
        <TextField
          label="Name"
          value={galname}
          onChange={(e) => setgalname(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          style={{ marginTop: 20 }}
          label="Subtitle"
          value={galsub}
          onChange={(e) => setgalsub(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          style={{ marginTop: 20 }}
          label="Thumbnail Url"
          value={galtumb}
          onChange={(e) => setgaltumb(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <FormControlLabel
        label="Public"
        fullWidth
        control={
          <Checkbox
            checked={gpub === "public"}
            onChange={(e) => setGpub(e.target.checked ? "public" : "private")}
          />
        }
      />
        {galelem.map((item, index) => (
          <div key={index} style={{ display: "flex" }}>
            <TextField
              style={{ marginTop: 20, marginRight: 10, flex: 1 }}
              label={`Image URL ${index + 1}`}
              value={item.url}
              onChange={(e) =>
                handleGalleryItemChange(index, "url", e.target.value)
              }
              fullWidth
              variant="outlined"
            />
            <TextField
              style={{ marginTop: 20, marginRight: 10, flex: 1 }}
              label={`Caption ${index + 1}`}
              value={item.caption}
              onChange={(e) =>
                handleGalleryItemChange(index, "caption", e.target.value)
              }
              fullWidth
              variant="outlined"
            />
            <Button
              variant="contained"
              style={{ marginTop: 20 }}
              color="secondary"
              onClick={() => handleRemoveGalleryItem(index)}
            >
              Remove
            </Button>
          </div>
        ))}

        <Button
          variant="contained"
          style={{ marginTop: 10 }}
          color="primary"
          onClick={handleAddGalleryItem}
        >
          Add Gallery Item
        </Button>

        <center>
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            color="primary"
            onClick={createAlbum}
            disabled={!galname || !galsub || !galtumb}
          >
            Create Album
          </Button>
        </center>
      </div>
    </div>
  );
};

export default UploadGallery;
