import React, { useState } from 'react'
import Button from '@mui/material/Button'
import { Delete } from '@mui/icons-material'
import apiurl from '../../../../apiurl'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ButtonGroup } from '@mui/material'
import axios from 'axios'
const CatAdapter = ({data,loadData}) => {
  const [deleteOpen,setDeleteOpen] = useState(false)
  const deleteCategory = ()=>{
    axios.delete(`${apiurl}/videocat/${data.id}`,{
      data:{
        id:data.id
      }
    }).then((res)=>{
      if(res.status===200){
        setDeleteOpen(false)
        loadData()
      }
    }).catch(err=> console.log(err))
  }
  return (
    <div className='flex border-b-2 border-gray-300 py-2 my-1 px-3'>
        <p className='flex-1'>{data.name}</p>
        <Button variant="contained" color="primary" onClick={e=> setDeleteOpen(!deleteOpen)}> 
          <Delete/>
        </Button>
        <Dialog open={deleteOpen} onClose={e=> setDeleteOpen(false)}>
          <DialogTitle>
            Are You sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to delete this category?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup variant="contained">
              <Button color="error" onClick={deleteCategory}>Delete</Button>
              <Button onClick={e=> setDeleteOpen(false)} color="primary">Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default CatAdapter