import { ListItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, ButtonGroup, TextField, Divider } from '@mui/material'
import axios from 'axios'
import { getAuth } from 'firebase/auth'
import React, { useState } from 'react'
import apiurl from '../../../apiurl'
const TogglePart = ({obj,loadData,id,ind,toggle}) => {
    const [edit, setedit] = useState(false)
    const [del, setdel] = useState(false)
    const [text, settext] = useState(obj.desc)

    const editelem =()=>{
        toggle[ind].desc = text
        setTimeout(() => {
            axios.patch(`${apiurl}/aboutpage`,{
                Headers:{'Content-type':'application/json'},
                id,
                toggle,
                token: getAuth().currentUser.uid,
            }).then((res)=>{
                setedit(false)
                alert('Updated..')
                loadData()
            }).catch((err)=>{
                console.log(err)
                alert('there is a problem')
            })
        }, 1000);
    }
    
    const delelem = ()=>{
        axios.delete(`${apiurl}/aboutpage/toggle`,{
            headers:{
                'Content-type':'application/json'
            },
            data:{
                id,
                token: getAuth().currentUser.uid,
                data:obj
            }
        }).then((res)=>{
            console.log(res.data)
            setdel(false)
            loadData()
        }).catch((err)=>{
            console.log(err)
            alert(err.message)
        })
    }
  return (
    <ListItem>
        <div className='flex w-full'>
            <h1 className='text-lg flex-1 text-black'>{obj.title}</h1>
            <ButtonGroup variant="contained" aria-label="">
                <Button variant="contained" color="primary" onClick={e=> setedit(true)}>
                Edit
                </Button>
                <Button color="secondary" onClick={e=> setdel(true)}>
                Delete
                </Button>
            </ButtonGroup>
            <Divider/>
        </div>

        <Dialog open={del} onClose={e=> setdel(false)}>
          <DialogTitle>
            Are you sure?
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
              <ButtonGroup variant="contained" color="primary">
                <Button onClick={delelem}>Delete</Button>
                <Button onClick={e=> setdel(false)}>Cancel</Button>
              </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog open={edit} onClose={e=> setedit(false)} aria-labelledby="Edit Dialog">
          <DialogTitle>
            {obj.title}
          </DialogTitle>
          <DialogContent>
              <div className='p-2'>
              <TextField
                label="Description"
                value={text}
                onChange={e=> settext(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
              </div>
          </DialogContent>
          <DialogActions>
              <ButtonGroup variant="contained">
                <Button color="primary" disabled={text===obj.desc} onClick={editelem}>Save</Button>
                <Button onClick={e=> setedit(false)} color="secondary">Cancel</Button>
              </ButtonGroup>
          </DialogActions>
        </Dialog>
    </ListItem>
  )
}

export default TogglePart