import { Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import React, { useState } from 'react'
import firebase from '../../firebase'
import {getAuth,onAuthStateChanged,sendPasswordResetEmail} from 'firebase/auth'
const ChangePassword = () => {
    const auth = getAuth(firebase)
    const [dia, setdia] = useState(false)
    const [diatitle, setdiatitle] = useState('')
    const [diadesc, setdiadesc] = useState('')
    const checkdata = ()=>{
        onAuthStateChanged(auth,(user)=>{
            if(user!=null){
                sendPasswordResetEmail(auth,user.email).then(()=>{
                    setdia(!dia)
                    setdiatitle('Done')
                    setdiadesc("Check your email and reset your password. Don't forget to check the spam box")
                }).catch((err)=>{
                    setdia(!dia)
                    setdiatitle('Error')
                    setdiadesc('Somthing Went Wrong!')
                    console.log(err)
                })
            }
            else{
                setdia(true)
                setdiatitle('Error')
                setdiadesc('It seems the session is expired. will you please refresh the page and login again?')
            }
        })
    }
  return (
    <div>
     <Typography variant="body1" color="grey" align="center">The E-mail cannot be changed. you can change your password only. A password reset link will be sent to your E-mail. You can change your password using it</Typography>
     <center>
        <Button variant="contained" color="primary" onClick={checkdata}>
          Send Link
        </Button>
     </center>
    <Dialog open={dia} onClose={e=> setdia(!dia)} aria-labelledby="">
      <DialogTitle>
        {diatitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {diadesc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={e=> setdia(!dia)} color="primary" variant="contained">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  )
}

export default ChangePassword