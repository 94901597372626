import React from 'react';

const BlogCard = ({ setDel, obj, setSelId, onEdit }) => {
  return (
    <div className='p-2 m-1 bg-white rounded-lg'>
      <img src={obj?.thumbnail} alt='Hello' className='w-100 h-100 rounded-md' />
      <h1 className='text-2xl font-bold my-2'>{obj.title}</h1>
      <p className='text-md text-gray-500'>{obj.description}</p>
      <p className='text-md text-gray-500'>{obj.body.substr(0, 200)}.....</p>
      <p className='mt-3 font-extralight text-md text-theme'>Uploaded On: {obj.createdAt}</p>
      <button onClick={() => onEdit(obj)} className='px-5 py-2 rounded-md bg-blue-700 text-white m-3'>Edit Blog</button>
      <button onClick={() => { setDel(true); setSelId(obj.id) }} className='px-5 py-2 rounded-md bg-red-700 text-white m-3'>Delete Blog</button>
    </div>
  );
};

export default BlogCard;
