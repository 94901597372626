import React, { useState } from 'react'
import { Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ButtonGroup } from '@mui/material'
import {Delete} from '@mui/icons-material'
const ContactAdapter = ({v,deleteData}) => {
    const [open, setopen] = useState(false)
  return (
    <div><Paper variant="elevation"><div className='m-2'>
    <p className='text-blue-600 m-1'>Full Name: <span className='text-gray-600'>{v.name}</span></p>
    <p className='text-blue-600 m-1'>E-mail: <span className='text-gray-600'>{v.email}</span></p>
    <p className='text-blue-600 m-1'>Department: <span className='text-gray-600'>{v.category}</span></p>
    <p className='text-blue-600 m-1'>Subject: <span className='text-gray-600'>{v.subject}</span></p>
    <p className='text-blue-600 m-1'>Message: <span className='text-gray-600'>{v.message}</span></p>
    <p className='m-2 pb-3'>Sent on: <span className='text-gray-600'>{v.createdAt}</span></p>
    </div>
    <div className='flex justify-end p-3'>
      <Button variant="contained" color="primary" onClick={e=> setopen(true)}>
        <Delete/> Delete
      </Button>
    </div>
    <Dialog open={open} onClose={e=> setopen(false)} aria-labelledby="Delete Dialog">
      <DialogTitle>
        Are you sure?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
            Are you sure that you want to delete this contact data?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <ButtonGroup variant="contained" aria-label="">
            <Button color='error' onClick={e=> {deleteData(v.id); setopen(false)}}>Delete</Button>
            <Button color="success" onClick={e=> setopen(false)}>Cancel</Button>
          </ButtonGroup>
      </DialogActions>
    </Dialog>
    </Paper>
    </div>
  )
}

export default ContactAdapter