import React, { useState } from "react";
import { Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

const CODCard = ({ obj, onDeleteOrder }) => {
  const products = obj.products
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = () => {
    onDeleteOrder(obj.id);
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="py-2">
      <Paper>
        <div className="p-3">
          <p className="text-gray-600 text-right">Order ID: {obj.id}</p>
          <p className="text-gray-600 text-right">Date: {obj.time}</p>
          <p className="text-theme text-red-600 text-xl text-right">
            Total: {obj.total}$
          </p>
          <div>
            <p className="text-blue-600 mb-3 uppercase font-bold text-xl">
              Product
            </p>
            {products.map((obj) => (
              <div key={obj.id}>
                <p className="text-blue-600">
                  Product: <span className="text-gray-600">{obj.name}</span>
                </p>
                <p className="text-blue-600">
                  Amount: <span className="text-gray-600">{obj.amount}</span>
                </p>
              </div>
            ))}
          </div>

          <p className="text-blue-600 mb-3 uppercase font-bold text-xl">
            Client
          </p>
          <p className="text-blue-600">
            Full Name: <span className="text-gray-600">{obj.fullName}</span>
          </p>
          <p className="text-blue-600">
            Email: <span className="text-gray-600">{obj.email}</span>
          </p>
          <p className="text-blue-600">
            Phone: <span className="text-gray-600">{obj.phone}</span>
          </p>
          <p className="text-blue-600">
            Address: <span className="text-gray-600">{obj.address}</span>
          </p>
          <p className="text-blue-600">
            City: <span className="text-gray-600">{obj.city}</span>
          </p>
          <p className="text-blue-600 my-3 uppercase font-bold text-xl">
            Payment Info
          </p>
          <p className="text-blue-600">
            Method: <span className="text-gray-600">{obj.method}</span>
          </p>
          <p className="text-blue-600">
            Status:{" "}
            <span className="text-gray-600">
              {obj.status ? "Not Paid" : "Paid"}
            </span>
          </p>
        </div>
        <Button variant="contained" color="secondary" onClick={() => setOpenDialog(true)}>
          Delete Order
        </Button>
      </Paper>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this product?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CODCard;
