import { List, TextField, Button } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import apiurl from '../../../apiurl'
import CatAdapter from './CatAdapter/CatAdapter'
const VideoCategory = () => {
  const [catName, setcatName] = useState('')
  const [data, setdata] = useState([])

  const loadData = ()=>{
    axios.get(`${apiurl}/videocat`).then((res)=>{
      if(res.status===200){
        console.log(res.data.statusCode)
        setdata(res.data.response.data)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  const crateCategory = ()=>{
    axios.post(`${apiurl}/videocat/`,{
      name: catName,
      key: catName.toLowerCase().trim()
    }).then((res)=>{
      if(res.data.statusCode===201){
        setcatName('')
        alert('Category Created')
        loadData()
      }
    }).catch((err)=>{
      alert('The category already exists')
    })
  }

  useEffect(() => {
    loadData()  
  }, [])
  
  return (
    <div>
        <p className='text-3xl text-center uppercase text-blue-600'>Add Video Category</p>
        <center>
            <TextField
              label="Category"
              value={catName}
              onChange={e=> setcatName(e.target.value)}
              style={{
                marginBottom:10,
                marginTop:10,
                width:'80%'}}
            /><br/>
            <Button variant="contained" color="primary" disabled={!catName} onClick={crateCategory}>
              Create Category
            </Button>
          </center>
        <p className="text-3xl text-center uppercase text-blue-600 mt-5">Existing Categories</p>
        <List>
            {data && data.map((v,i)=>{
              return <CatAdapter data={v} key={i} loadData={loadData}/>
            })}
        </List>
    </div>
  )
}

export default VideoCategory