import { List, ListItem } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState} from 'react'
import apiurl from '../../apiurl'
import excelfile from 'export-from-json'
import {Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ButtonGroup} from '@mui/material'
import {Download} from '@mui/icons-material'
import {AuthContext} from "../../hooks/AuthContext"

const Booking = () => {
  const [bookarr, setbookarr] = useState([])
  const [oarr, setoarr] = useState([])
  const [date, setdate] = useState('')
  const [bookdate, setbookdate] = useState('')
  const [filter, setfilter] = useState('')
  const [delopen, setdelopen] = useState(false)
  const user = useContext(AuthContext)
  console.log(user)
  const getDatas = () =>{
    axios.get(`${apiurl}/bookNow`,{
    }).then((res)=>{
      if(res.status === 200){
        console.log(res.data.response.data)
        setbookarr(res.data.response.data.reverse())
        
      }
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    getDatas()
  }, [])
  
  useEffect(() => {
    setoarr(bookarr)
  }, [bookarr])

  useEffect(() => {
    if(date){
      setoarr(bookarr.filter((v,i)=> new Date(v.dateOfService).toString() === date.toString()))
    }
  }, [date])

  useEffect(() => {
    if(bookdate){
      setoarr(bookarr.filter((v,i)=> new Date(v.dateOfService).toString() === bookdate.toString()))
    }
  }, [bookdate])

  const deleteData = (id)=>{
    axios.delete(`${apiurl}/bookNow/${id}`).then((res)=>{
      if(res.status === 200){
        console.log(res)
        setdelopen(false)
        getDatas()
      }
    }).catch((err)=>{
      alert(err)
    })
  }
  
  
  const filterData = (e)=>{
    setfilter(e)
    setoarr(bookarr.filter((v,i)=> v.firstName.includes(e) || v.phoneNumber.includes(e) || v.email.includes(e) || v.serviceRequired.includes(e)))
  }
  const exportinxl = ()=>{
    const data = Object.assign(oarr,{})
    const fileName = `bookings-${new Date().getTime()}`
    const exportType =  excelfile.types.xls

    excelfile({data,fileName,exportType})
  }
  return (
    <div>
      <h1 className='text-center text-3xl text-blue-600 uppercase'>Bookings</h1>
      <div className='flex flex-col md:flex-row mt-2'>
        <div className='p-2'>
          <label>Filled On: </label>
        <input type="date" onChange={e=> setdate(new Date(e.target.value))} placeholder='Pick the date'/>
        <Button variant="contained" color="primary" disabled={!date} onClick={e=> {setdate(''); setoarr(bookarr)}}>
          Reset
        </Button>
        </div>

        <div className='p-2'>
          <label>Booked On: </label>
        <input type="date" onChange={e=> setbookdate(new Date(e.target.value))} placeholder='Pick the date'/>
        <Button variant="contained" color="primary" disabled={!bookdate} onClick={e=> {setbookdate(''); setoarr(bookarr)}}>
          Reset
        </Button>
        </div>
        <div className='p-3 flex'>
          <TextField
            label="Search"
            fullWidth
            value={filter}
            onChange={(e)=>filterData(e.target.value)}
            variant="outlined"
          />
          <Button variant="contained" style={{marginLeft:10}} color="primary" disabled={!filter} onClick={e=> {setfilter(''); setoarr(bookarr)}}>
            Reset
          </Button>
        </div>
        <div className='p-3'>
          <Button variant="contained" color="primary" disabled={oarr.length<1} onClick={exportinxl}>
             <Download/> Export In Excel
          </Button>
        </div>
      </div>
      <List>
        <Paper>
        {oarr.map((v,i)=> <ListItem>
          <div className='shadow-gray-400 shadow-md w-full p-3'>
            <p className='text-blue-600 my-1'>First Name : <span className='text-gray-600'>{v.firstName}</span></p>
            <p className='text-blue-600 my-1'>Last Name : <span className='text-gray-600'>{v.lastName}</span></p>
            <p className='text-blue-600 my-1'>Phone Number : <span className='text-gray-600'>{v.phoneNumber}</span></p>
            <p className='text-blue-600 my-1'>Email : <span className='text-gray-600'>{v.email}</span></p>
            <p className='text-blue-600 my-1'>Service Required : <span className='text-gray-600'>{v.serviceRequired}</span></p>
            <p className='text-blue-600 my-1'>Booked Date: <span className='text-gray-600'>{new Date(v.dateOfService).toLocaleDateString()}</span></p>
            <p className='text-blue-600 my-1'>Form filled on : <span className='text-gray-600'>{new Date(v.createdAt).toLocaleString()}</span></p>
            <div>
              <Button variant="contained" color="primary" onClick={e=> setdelopen(true)}>
                Delete
              </Button>
            </div>
                <Dialog open={delopen} onClose={e=> setdelopen(!delopen)}>
            <DialogTitle>
              Delete?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure that you want to delete this?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonGroup variant="contained" color="primary" aria-label="">
                <Button onClick={e=> deleteData(v.id)}>Delete</Button>
                <Button onClick={e=> setdelopen(false)}>Cancel</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
          </div>
        </ListItem>)}
        </Paper>
      </List>
     
    </div>
  )
}

export default Booking