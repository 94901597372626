import React, { useEffect, useState } from "react";
import apiurl from "../../apiurl";
import axios from "axios";
import PayPalCard from "./PayPalCard/PayPalCard";
import CODCard from "./CODCard/CODCard";
import { Button, TextField } from "@mui/material";
import excelfile from "export-from-json";
import { Download } from "@mui/icons-material";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [oarr, setOarr] = useState([]);
  const [filter, setFilter] = useState("");

  const loadData = () => {
    axios
      .get(`${apiurl}/order`, {})
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data.response.data?.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setOarr(orders);
  }, [orders]);

  const filterData = (e) => {
    setFilter(e);
    setOarr(
      orders.filter(
        (v, i) =>
          v.fullName.includes(e) ||
          v.time.includes(e) ||
          v.email.includes(e) ||
          v.phone.includes(e)
      )
    );
  };
  

  const exportToExcel = () => {
    const data = Object.assign(oarr, {});
    const fileName = `orders-${new Date().getTime()}`;
    const exportType = excelfile.types.xls;
    excelfile({ data, fileName, exportType });
  };

  const handleDeleteOrder = (orderId) => {
    axios
      .delete(`${apiurl}/order/${orderId}`)
      .then(() => {
        alert("Order deleted successfully");
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <h1 className="text-center my-3 text-blue-600 uppercase text-3xl">
        Orders
      </h1>
      <div className="p-3 flex mt-2 flex-col md:flex-row">
        <div className="p-3 flex flex-1">
          <TextField
            label="Search"
            fullWidth
            value={filter}
            onChange={(e) => filterData(e.target.value)}
            variant="outlined"
          />
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            color="primary"
            disabled={!filter}
            onClick={(e) => {
              setFilter("");
              setOarr(orders);
            }}
          >
            Reset
          </Button>
        </div>
        <div className="p-3">
          <Button
            variant="contained"
            color="primary"
            disabled={oarr.length < 1}
            onClick={exportToExcel}
          >
            <Download /> Export In Excel
          </Button>
        </div>
      </div>
      {oarr?.map((v, i) => {
        if (v.method === "PayPal") {
          return (
            <PayPalCard key={v.id} obj={v} onDeleteOrder={handleDeleteOrder} />
          );
        } else {
          return (
            <CODCard key={v.id} obj={v} onDeleteOrder={handleDeleteOrder} />
          );
        }
      })}
    </div>
  );
};

export default Orders;
