import React, { useState } from 'react';
import { Paper, ButtonGroup, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import apiurl from '../../../apiurl';

const CategoryAdapter = ({ obj, data, id, ind, mainid, loadData }) => {
  const [del, setDel] = useState(false);

  const deleteElem = () => {
    const updatedCats = data.filter((cat, index) => index !== ind);
    const updatedCategory = {
      ...obj,
      cats: updatedCats
    };

    axios.put(`${apiurl}/category/${mainid}`, updatedCategory)
      .then((res) => {
        setDel(false);
        loadData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className='p-1'>
      <Paper variant='outlined'>
        <div className='flex items-center'>
          <p className='flex-1 text-xl'>{obj.display}</p>
          <ButtonGroup variant="contained" color="primary" aria-label="">
            <Button onClick={() => setDel(true)}><Delete /></Button>
          </ButtonGroup>
        </div>

        <Dialog open={del} onClose={() => setDel(false)} aria-labelledby={""}>
          <DialogTitle>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to delete this category?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup variant="contained" aria-label="">
              <Button onClick={deleteElem} primary="secondary">Delete</Button>
              <Button color="primary" onClick={() => setDel(false)}>Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  )
}

export default CategoryAdapter;
