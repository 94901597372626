import React from 'react'
import LinkUpload from './PcloudUpload/LinkUpload'


const UploadVideo = () => {

  return (
    <div>
      <p className='text-center text-blue-600 text-3xl uppercase'>Upload Options</p>
        <LinkUpload/>
        {/* <ServerUpload/> */}
    </div>
  )
}

export default UploadVideo