import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import apiurl from "../../../apiurl";

const VideoUsers = () => {
  const [openChangeAccessDialog, setOpenChangeAccessDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);

  useEffect(() => {
    axios.get(`${apiurl}/user/`).then((res) => {
      const users = res.data.response.data;
      const filteredUsers = users.filter((user) => user.role === "client");
      setUsers(filteredUsers);
    });
  }, [updateStatus]);

  useEffect(() => {
    axios.get(`${apiurl}/video/`).then((res) => {
      const videos = res.data.videos;
      const filteredVideos = videos.filter(
        (video) => video.privacy === "private"
      );
      setVideos(filteredVideos);
    });
  }, [updateStatus]);

  const handleOpenChangeAccessDialog = (user) => {
    setSelectedUser(user.id);
    setSelectedVideos(
      user.videos ? user.videos.map((video) => video.title) : []
    );
    setOpenChangeAccessDialog(true);
  };

  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleSelectVideo = (event) => {
    const { value } = event.target;
    setSelectedVideos(value);
  };

  const handleDeleteVideo = (videoTitle) => {
    const updatedSelectedVideos = selectedVideos.filter(
      (title) => title !== videoTitle
    );
    setSelectedVideos(updatedSelectedVideos);
  };

  const assignVideos = (userId) => {
    const userData = {
      videos: selectedVideos.map((title) => {
        const video = videos.find((video) => video.title === title);
        return {
          id: video.id,
          title: video.title,
          link: video.link,
        };
      }),
    };

    axios
      .put(`${apiurl}/user/${userId}`, userData)
      .then((response) => {
        alert("Video assigned to the user successfully");
        console.log(response.data);
        setOpenChangeAccessDialog(false);
        setUpdateStatus(!updateStatus);
      })
      .catch((error) => {
        console.error(error);
        // Handle error
      });
  };

  return (
    <div>
      <h1 className="text-center text-xl">Video Users</h1>
      <List>
        {users.map((user) => (
          <ListItemButton key={user.id}>
            <ListItemText
              primary={user.first_name}
              secondary={
                <>
                  <span>{user.email}</span>
                  <br />
                  <span>Access Control:</span>
                </>
              }
            />

            <ButtonGroup variant="contained" aria-label="">
              <Button
                color="primary"
                onClick={() => handleOpenChangeAccessDialog(user)}
              >
                Change Access
              </Button>
              <Button
                color="error"
                onClick={() => handleOpenDeleteDialog(user)}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ListItemButton>
        ))}
      </List>

      <Dialog
        open={openChangeAccessDialog}
        onClose={() => setOpenChangeAccessDialog(false)}
        sx={{ "& .MuiDialog-paper": { width: "500px" } }}
      >
        <DialogTitle>Change Access</DialogTitle>
        <DialogContent>
          <DialogContentText>Assigned videos:</DialogContentText>
          {selectedVideos.length > 0 ? (
            <List>
              {selectedVideos.map((videoTitle) => (
                <ListItemButton key={videoTitle}>
                  <ListItemText primary={videoTitle} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteVideo(videoTitle)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          ) : (
            <DialogContentText>No videos assigned yet.</DialogContentText>
          )}

          <DialogContentText>Assign videos:</DialogContentText>
          <Select
            multiple
            value={selectedVideos}
            onChange={handleSelectVideo}
            fullWidth
          >
            {videos.map((video) => (
              <MenuItem key={video.id} value={video.title}>
                {video.title}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => setOpenChangeAccessDialog(false)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => assignVideos(selectedUser)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
           You can delete the user from users tab
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoUsers;
