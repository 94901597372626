import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import UploadGallery from './Upload/UploadGallery'
import Uploaded from './Uploaded/Uploaded'
import GalleryUsers from './GalleryUsers/GalleryUsers'

const Gallery = () => {
  const [tab, settab] = useState(0)
  const compArr = [<Uploaded/>,<UploadGallery/>,<GalleryUsers/>]
  const handleChange = (event, newValue) => {
    settab(newValue);
  };
  return (
    <div>
      <Box sx={{width:'100%'}}>
        <center>
        <Box sx={{borderBottom:1, borderColor:'divider'}}>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Uploaded Galleries"/>
            <Tab label="Upload Gallery"/>
            <Tab label="User Controls"/>
          </Tabs>
        </Box>
        </center>
      </Box>
      <div>
        {compArr[tab]}
      </div>
    </div>
  )
}

export default Gallery