import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import apiurl from "../../../apiurl";

const GalleryUsers = () => {
  const [openChangeAccessDialog, setOpenChangeAccessDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedgalleries, setSelectedGalleries] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);

  useEffect(() => {
    axios.get(`${apiurl}/user/`).then((res) => {
      const users = res.data.response.data;
      const filteredUsers = users.filter((user) => user.role === "client");
      setUsers(filteredUsers);
    });
  }, [updateStatus]);

  useEffect(() => {
    axios.get(`${apiurl}/gallery/`).then((res) => {
      const gallery = res.data.response.data;
      const filteredGal = gallery.filter(
        (gal) => gal.privacy === "private"
      );
      setGalleries(filteredGal);
    });
  }, [updateStatus]);


  const handleOpenChangeAccessDialog = (user) => {
    setSelectedUser(user.id);
    setSelectedGalleries(
      user.gallery ? user.gallery.map((gal) => gal.title) : []
    );
    setOpenChangeAccessDialog(true);
  };

  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleSelectGallery = (event) => {
    const { value } = event.target;
    setSelectedGalleries(value);
  };

  const handleDeleteGallery = (galleryTitle) => {
    const updatedSelectedGal = selectedgalleries.filter(
      (title) => title !== galleryTitle
    );
    setSelectedGalleries(updatedSelectedGal);
  };

  const assignGalleries = (userId) => {
    console.log(userId);
    const userData = {
      id: userId,
      gallery: selectedgalleries.map((title) => {
        console.log("title:", title);
        const gallery = galleries.find((gal) => gal.title === title);
        console.log("gallery:", gallery);
        return {
          id: gallery.id,
          title: gallery.title,
          thumbnailUrl: gallery.thumbnailUrl,
        };
      }),
    };
  
    const updatedUserData = {
      ...userData,
      gallery: userData.gallery || [], // Ensure gallery is not null but an empty array if not assigned
    };

    axios
      .put(`${apiurl}/user/${userId}`, updatedUserData)
      .then((response) => {
        alert("Gallery assigned to the user successfully");
        console.log(response.data);
        setOpenChangeAccessDialog(false);
        setUpdateStatus(!updateStatus);
      })
      .catch((error) => {
        console.error(error);
        // Handle error
      });
  };

  return (
    <div>
      <h1 className="text-center text-xl">Gallery Users</h1>
      <List>
        {users.map((user) => (
          <ListItemButton key={user.id}>
            <ListItemText
              primary={user.first_name}
              secondary={
                <>
                  <span>{user.email}</span>
                  <br />
                  <span>Access Control:</span>
                </>
              }
            />

            <ButtonGroup variant="contained" aria-label="">
              <Button
                color="primary"
                onClick={() => handleOpenChangeAccessDialog(user)}
              >
                Change Access
              </Button>
              <Button
                color="error"
                onClick={() => handleOpenDeleteDialog(user)}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ListItemButton>
        ))}
      </List>

      <Dialog
        open={openChangeAccessDialog}
        onClose={() => setOpenChangeAccessDialog(false)}
        sx={{ "& .MuiDialog-paper": { width: "500px" } }}
      >
        <DialogTitle>Change Access</DialogTitle>
        <DialogContent>
          <DialogContentText>Assigned Galleries:</DialogContentText>
          {selectedgalleries.length > 0 ? (
            <List>
              {selectedgalleries.map((galleryTitle) => (
                <ListItemButton key={galleryTitle}>
                  <ListItemText primary={galleryTitle} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteGallery(galleryTitle)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          ) : (
            <DialogContentText>No Galleries assigned yet.</DialogContentText>
          )}

          <DialogContentText>Assign Galleries:</DialogContentText>
          <Select
            multiple
            value={selectedgalleries}
            onChange={handleSelectGallery}
            fullWidth
          >
            {galleries.map((gal) => (
              <MenuItem key={gal.id} value={gal.title}>
                {gal.title}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => setOpenChangeAccessDialog(false)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => assignGalleries(selectedUser)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
           You can delete the user from users tab
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GalleryUsers;
