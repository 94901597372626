import { Add } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import ProCard from './ProCard/ProCard'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material'
import axios from 'axios'
import apiurl from '../../apiurl'


const Products = () => {
  const [data, setdata] = useState([])
  const [cats, setcats] = useState([])
  const [name, setname] = useState('')
  const [cat, setcat] = useState('')
  const [price, setprice] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [sale, setsale] = useState(false)
  const [desc, setdesc] = useState('')

  const loadData = ()=>{
    axios.get(`${apiurl}/product`).then((res)=>{
      setdata(res.data.response.data)
    }).catch((err)=> console.log(err))
    axios.get(`${apiurl}/category`).then((res)=>{
      if(res.status===200){
        res.data.response.data.map((v,i)=>{
          if(v.type === "product"){
            setcats(v.cats)
          }
          return(true)
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(() => {
    loadData()
  }, [])
  
  const createProduct = async ()=>{
    // let data = new FormData()
    //     data.append("avatar",thumb)
    //     const response = await axios({
    //     method: 'post',
    //     url: 'https://fileapi.byachtservices.com/upload.php',
    //     data: data,
    //     headers: {
    //         'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    //     },
    // });
    // if(response.status === 200){
      axios.post(`${apiurl}/product`,{
        Headers:{
          'Content-type':'application/json'
        },
        name,
        description:desc,
        category:cat,
        price,
        comments:[],
        sale,
        imageUrl:imageUrl,
      }).then((res)=>{
        console.log(res.data.result)
        setname('')
        setprice('')
        setdesc('')
        setcat('')
        setsale(false)
        setImageUrl('')
        loadData()
      }).catch((err)=> console.log(err))
  }


  return (
    <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1'>
      <div className='ring-1 ring-gray-600 rounded-md p-3'>
        <center>
        <Add style={{fontSize:100}}/>
        <div className='p-2'>
          <TextField
            label="Product Name"
            value={name}
            onChange={e=> setname(e.target.value)}
            fullWidth
            variant='outlined'
          />
          <TextField
            label="Price"
            value={price}
            onChange={e=> setprice(e.target.value)}
            style={{marginTop:20}}
            fullWidth
            variant='outlined'
          />
          <TextField
            label="Description"
            value={desc}
            onChange={e=> setdesc(e.target.value)}
            style={{marginTop:20}}
            fullWidth
            variant='outlined'
            multiline
            rows={4}
          />
           <TextField
            label="imageUrl"
            value={imageUrl}
            onChange={e=> setImageUrl(e.target.value)}
            style={{marginTop:20}}
            fullWidth
            variant='outlined'
          />
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cat}
            label="Category"
            style={{marginTop:20}}
            onChange={e=> setcat(e.target.value)}
          >
            {cats.map((v,i)=> <MenuItem value={v.key}>{v.display}</MenuItem>)}
          </Select>
        </FormControl>
        </div>
        <FormControlLabel
          label="Sale"
          control={
            <Checkbox
              value="Sale"
              checked={sale}
              onChange={e=> setsale(!sale)}
              color="primary"
            />
          }
        /><br/>
        <Button variant="contained" color="primary" disabled={!name || !desc || !price || !imageUrl || !cat} onClick={createProduct}>
          create
        </Button>
        </center>
      </div>
      {data.map((v,i)=> <ProCard obj={v} key={i} loadData={loadData} cats={cats}/>)}
    </div>
  )
}

export default Products