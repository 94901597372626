import React, { useEffect, useState } from 'react';
import BlogCard from './BlogCard/BlogCard';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import apiurl from '../../apiurl';
import axios from 'axios';

const Blogs = () => {
  const [del, setDel] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [selId, setSelId] = useState('');
  const [editBlog, setEditBlog] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editBody, setEditBody] = useState('');
  const [editComments, setEditComments] = useState([]);

  const loadData = () => {
    axios.get(`${apiurl}/blog`).then((res) => {
      setBlogs(res.data.response.data);
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);

  const deleteBlog = () => {
    if (selId) {
      axios.delete(`${apiurl}/blog/${selId}`, {
        data: {
          id: selId,
        },
      }).then((res) => {
        console.log(res.data);
        loadData();
        setDel(false);
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const editBlogHandler = () => {
    if (editBlog && editTitle && editBody) {
      const updatedBlog = {
        ...editBlog,
        title: editTitle,
        body: editBody,
        comments: editComments,
      };
      axios.put(`${apiurl}/blog/${editBlog.id}`, updatedBlog)
        .then((res) => {
          console.log(res.data);
          loadData();
          setEditBlog(null);
          setEditTitle('');
          setEditBody('');
          setEditComments([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const openEditDialog = (blog) => {
    setEditBlog(blog);
    setEditTitle(blog.title);
    setEditBody(blog.body);
    setEditComments(blog.comments);
  };

  const closeEditDialog = () => {
    setEditBlog(null);
    setEditTitle('');
    setEditBody('');
    setEditComments([]);
  };

  const removeComment = (index) => {
    const updatedComments = [...editComments];
    updatedComments.splice(index, 1);
    setEditComments(updatedComments);
  };

  return (
    <>
      <div className='grid md:grid-cols-4 grid-cols-1 gap-4'>
        {blogs.map((v, i) => (
          <BlogCard
            key={i}
            setDel={setDel}
            setSelId={setSelId}
            obj={v}
            onEdit={openEditDialog}
          />
        ))}
      </div>
      <Dialog open={del} onClose={() => setDel(false)} aria-labelledby="Blog Delete Dialog">
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete this blog?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteBlog} variant="contained" color="error">
            Delete
          </Button>
          <Button onClick={() => setDel(false)} color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editBlog !== null} onClose={closeEditDialog} aria-labelledby="Blog Edit Dialog">
        <DialogTitle>Edit Blog</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            fullWidth
            variant="outlined"
            margin="dense"
          />
          <TextField
            label="Body"
            value={editBody}
            onChange={(e) => setEditBody(e.target.value)}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            margin="dense"
          />
          <div>
            <p>Edit Comments:</p>
            {editComments?.map((comment, index) => (
              <div key={index} className="flex items-center mb-2">
                <TextField
                  label={`Comment ${index + 1}`}
                  value={comment.comment}
                  onChange={(e) => {
                    const updatedComments = [...editComments];
                    updatedComments[index].comment = e.target.value;
                    setEditComments(updatedComments);
                  }}
                  multiline
                  rows={2}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                />
                <Button
                  onClick={() => removeComment(index)}
                  color="error"
                  variant="contained"
                  size="small"
                  className="ml-2"
                >
                  Remove
                </Button>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={editBlogHandler} variant="contained">
            Save
          </Button>
          <Button onClick={closeEditDialog} color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Blogs;
