import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { FormControlLabel, Checkbox } from "@mui/material";

import axios from "axios";
import apiurl from "../../../../apiurl";

const UploadedAdapter = ({ obj, loadData }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(obj.title);
  const [subtitle, setSubtitle] = useState(obj.subtitle);
  const [thumbnailUrl, setThumbnailUrl] = useState(obj.thumbnailUrl);
  const [gpub, setGpub] = useState(obj.privacy ? "public" : "private");
  const [elements, setElements] = useState(obj.elements);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    axios
      .put(`${apiurl}/gallery/${obj.id}`, {
        title: title,
        subtitle: subtitle,
        thumbnailUrl: thumbnailUrl,
        privacy: gpub,
        elements: elements,
      })
      .then((res) => {
        if (res.status === 200) {
          loadData(); // Reload gallery data
          setOpen(false); // Close the modal
        }
      })
      .catch((err) => {
        console.log(err);
        // Handle error
      });
  };

  const handleDelete = () => {
    setOpen(false); // Close the modal

    // Show confirmation dialog before deleting
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this gallery?"
    );
    if (confirmDelete) {
      axios
        .delete(`${apiurl}/gallery/${obj.id}`)
        .then((res) => {
          if (res.status === 200) {
            loadData(); // Reload gallery data
          }
        })
        .catch((err) => {
          console.log(err);
          // Handle error
        });
    }
  };

  const handleAddElement = () => {
    const newElement = { caption: "", image: "" };
    setElements([...elements, newElement]);
  };

  const handleRemoveElement = (index) => {
    const updatedElements = [...elements];
    updatedElements.splice(index, 1);
    setElements(updatedElements);
  };

  const handleElementChange = (index, field, value) => {
    const updatedElements = [...elements];
    updatedElements[index][field] = value;
    setElements(updatedElements);
  };

  return (
    <div className="p-2 m-2 rounded-md ring-gray-500 ring-1">
      <img src={obj.thumbnailUrl} className="h-40" alt="Please reload.." />
      <p className="text-xl text-black">{obj.title}</p>
      <p className="text-gray-600">{obj.subtitle}</p>
      <center>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Edit
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </center>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Gallery</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the details of the gallery:
          </DialogContentText>

          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Subtitle"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Thumbnail URL"
            value={thumbnailUrl}
            onChange={(e) => setThumbnailUrl(e.target.value)}
            fullWidth
            margin="normal"
          />

          <FormControlLabel
            label="Public"
            style={{ margin: 5 }}
            control={
              <Checkbox
                checked={gpub === "public"}
                onChange={(e) =>
                  setGpub(e.target.checked ? "public" : "private")
                }
              />
            }
          />

          {elements.map((element, index) => (
            <div key={index} style={{ display: "flex" }}>
              <TextField
                label="Caption"
                value={element.caption}
                style={{ marginTop: 20, marginRight: 10, flex: 1 }}
                onChange={(e) =>
                  handleElementChange(index, "caption", e.target.value)
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label={`Image URL ${index + 1}`}
                style={{ marginTop: 20, marginRight: 10, flex: 1 }}
                value={element.image}
                onChange={(e) =>
                  handleElementChange(index, "image", e.target.value)
                }
                fullWidth
                margin="normal"
              />

              {elements.length > 1 && (
                <Button
                size="xs"
                variant="contained"
                style={{ marginTop: 20 }}
                color="secondary"
                onClick={() => handleRemoveElement(index)}
              >
                Remove
              </Button>
              )}
            </div>
          ))}

          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            color="primary"
            onClick={handleAddElement}
          >
            Add Gallery Item
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadedAdapter;
