import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import apiurl from "../../../../apiurl";
import axios from "axios";

const LinkUpload = () => {
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [cat, setCat] = useState("");
  const [vidthumb, setVidthumb] = useState("");
  const [vpub, setVpub] = useState("public");
  const [chapters, setChapters] = useState([]);
  const [cats, setCats] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false); // Track form validation

  const getCats = () => {
    axios
      .get(`${apiurl}/videocat`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.response);
          setCats(res.data.response.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCats();
  }, []);

  useEffect(() => {
    const isValid =
      link.trim() !== "" &&
      name.trim() !== "" &&
      cat.trim() !== "" &&
      vidthumb.trim() !== "";

    setIsFormValid(isValid);
  }, [link, name, cat, vidthumb]);

  const handleAdd = () => {
    const videoData = {
      title: name,
      link: link,
      thumbnail: vidthumb,
      category: cat,
      privacy: vpub,
      chapters: chapters,
    };

    axios
      .post(`${apiurl}/video`, videoData)
      .then((res) => {
        alert("Video Uploaded..");
        // Reset the fields
        setName("");
        setLink("");
        setVidthumb("");
        setCat("");
        setVpub("public");
        setChapters([]);
      })
      .catch((err) => {
        console.log(err);
        alert("There is a problem");
      });
  };

  const handleChapterTitleChange = (index, value) => {
    const updatedChapters = [...chapters];
    updatedChapters[index] = {
      ...updatedChapters[index],
      title: value,
    };
    setChapters(updatedChapters);
  };

  const handleChapterStartChange = (index, value) => {
    const updatedChapters = [...chapters];
    updatedChapters[index] = {
      ...updatedChapters[index],
      start: value,
    };
    setChapters(updatedChapters);
  };

  const handleAddChapter = () => {
    const newChapter = { title: "", start: 0 };
    setChapters([...chapters, newChapter]);
  };

  const handleRemoveChapter = (index) => {
    const updatedChapters = [...chapters];
    updatedChapters.splice(index, 1);
    setChapters(updatedChapters);
  };

  return (
    <div className="p-3 ring-1 ring-gray-400 m-2 rounded-lg">
      <h2 className="text-blue-600 text-2xl mb-4">Upload From Link</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          label="Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          variant="outlined"
          fullWidth
        />
        <TextField
          label="Thumbnail Url"
          value={vidthumb}
          onChange={(e) => setVidthumb(e.target.value)}
          variant="outlined"
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cat}
            label="Category"
            onChange={(e) => setCat(e.target.value)}
          >
            {cats &&
              cats.map((v, i) => (
                <MenuItem value={v.key} key={i}>
                  {v.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <FormControlLabel
        label="Public"
        control={
          <Checkbox
            checked={vpub === "public"}
            onChange={(e) => setVpub(e.target.checked ? "public" : "private")}
          />
        }
      />
      <div className="mt-6">
        <h2 className="text-blue-600 text-2xl mb-2">Add Chapters</h2>
        {chapters.map((chapter, index) => (
          <div key={index} className="flex items-center mb-4">
            <TextField
              label={`Chapter ${index + 1} Title`}
              value={chapter.title}
              onChange={(e) => handleChapterTitleChange(index, e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginRight: "1rem" }}
            />
            <TextField
              label={`Chapter ${index + 1} Start`}
              type="number"
              value={chapter.start}
              onChange={(e) =>
                handleChapterStartChange(index, parseInt(e.target.value))
              }
              fullWidth
              variant="outlined"
              sx={{ marginRight: "1rem" }}
            />

            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveChapter(index)}
            >
              Remove Chapter
            </Button>
          </div>
        ))}
        <div className="flex items-center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddChapter}
            className="mr-4"
          >
            Add Chapter
          </Button>
        </div>
      </div>
      <div className="text-center mt-4">
        <Button variant="contained" color="primary" onClick={handleAdd} disabled={!isFormValid}>
          Add Video
        </Button>
      </div>
    </div>
  );
};

export default LinkUpload;
