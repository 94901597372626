import React, { useContext } from 'react';
import './App.css';
import { AuthContext } from './hooks/AuthContext';
import { Paper } from '@mui/material';
import Dashboard from './Components/Dashboard/Dashboard';
import Login from './Components/Login/Login';

function App() {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <Paper variant="outlined" style={{ padding: 20 }}>
        {user ? <Dashboard /> : <Login />}
      </Paper>
    </div>
  );
}

export default App;
