import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  SwipeableDrawer,
  Fab,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AddBox,
  Book,
  Category,
  ContactMail,
  Edit,
  FormatQuote,
  Image,
  Menu,
  RequestQuote,
  Security,
  Shop,
  Shop2,
  Style,
} from "@mui/icons-material";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import Booking from "../Booking/Booking";
import AboutUI from "../AboutUsUi/AboutUsUi";
import Blogs from "../Blogs/Blogs";
import Contact from "../Contact/Contact";
import Gallery from "../Gallery/Gallery";
import HeaderFooter from "../HeaderFooter/HeaderFooter";
import HomeUI from "../HomePageUI/HomePageUI";
import Orders from "../Orders/Orders";
import Products from "../Products/Products";
import QuotationRequest from "../QuotationRequest/QuotationRequest";
import Testimonial from "../Testimonial/Testimonial";
import QuotationAdmin from "../QuotationAdmin/QuotationAdmin";
import Categories from "../Categories/Categories";
import Videos from "../Videos/Videos";
import ChangePassword from "../ChangePassword/ChangePassword";
import User from "../User/User";

const Navbar = ({ setselcompo }) => {
  const [selected, setselected] = useState();
  const [menu, setmenu] = useState(false);
  useEffect(() => {
    setmenu(m => !m);
  }, [selected]);
  

  const handlelogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  }


  return (
    <div>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <Fab onClick={() => setmenu(!menu)} color="primary" aria-label="">
            <Menu />
          </Fab>
          <Typography variant="h6" style={{ marginLeft: 10 }}>
            {selected}
          </Typography>
          <Button
            style={{ position: "fixed", right: 2 }}
            variant="contained"
            color="primary"
            onClick={handlelogout}
          >
            logout
          </Button>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        open={menu}
        onClose={() => setmenu(!menu)}
        onOpen={() => setmenu(!menu)}
        className="p-3"
      >
        <h1 className="uppercase mt-5 text-blue-600 text-center text-2xl font-bold">
          Bonaventure Services
        </h1>
        <p className="p-2 text-center italic">Created By Syed Haris, With Love!</p>
        <List>
          <ListItem
            button
            onClick={() => {
              setselected("Bookings");
              setselcompo(<Booking />);
            }}
          >
            <ListItemIcon>
              <Book />
            </ListItemIcon>
            <ListItemText>Bookings</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("HomePage");
              setselcompo(<HomeUI />);
            }}
          >
            <ListItemIcon>
              <Style />
            </ListItemIcon>
            <ListItemText>HomePage UI</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("QuotationRequest");
              setselcompo(<QuotationRequest />);
            }}
          >
            <ListItemIcon>
              <RequestQuote />
            </ListItemIcon>
            <ListItemText>Quotation Requests</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("QuotationAdmin");
              setselcompo(<QuotationAdmin />);
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Quotation Edit</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Categories");
              setselcompo(<Categories />);
            }}
          >
            <ListItemIcon>
              <Category />
            </ListItemIcon>
            <ListItemText>Categories</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("AboutUsUI");
              setselcompo(<AboutUI />);
            }}
          >
            <ListItemIcon>
              <Style />
            </ListItemIcon>
            <ListItemText>About Us UI</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Testimonial");
              setselcompo(<Testimonial />);
            }}
          >
            <ListItemIcon>
              <FormatQuote />
            </ListItemIcon>
            <ListItemText>Testimonial</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Gallery");
              setselcompo(<Gallery />);
            }}
          >
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText>Gallery</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Products");
              setselcompo(<Products />);
            }}
          >
            <ListItemIcon>
              <Shop />
            </ListItemIcon>
            <ListItemText>Products</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setselected("Orders");
              setselcompo(<Orders />);
            }}
          >
            <ListItemIcon>
              <Shop2 />
            </ListItemIcon>
            <ListItemText>Orders</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setselected("Blogs");
              setselcompo(<Blogs />);
            }}
          >
            <ListItemIcon>
              <AddBox />
            </ListItemIcon>
            <ListItemText>Blogs</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Videos");
              setselcompo(<Videos />);
            }}
          >
            <ListItemIcon>
              <MovieFilterIcon />
            </ListItemIcon>
            <ListItemText>Videos</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Contact");
              setselcompo(<Contact />);
            }}
          >
            <ListItemIcon>
              <ContactMail />
            </ListItemIcon>
            <ListItemText>Contact</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("User Details");
              setselcompo(<User />);
            }}
          >
            <ListItemIcon>
              <ContactMail />
            </ListItemIcon>
            <ListItemText>User Details</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setselected("Company Details");
              setselcompo(<HeaderFooter />);
            }}
          >
            <ListItemIcon>
              <Style />
            </ListItemIcon>
            <ListItemText>Company Details</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setselected("ChangePassword");
              setselcompo(<ChangePassword />);
            }}
          >
            <ListItemIcon>
              <Security />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </div>
  );
};

export default Navbar;
