import React, { useEffect, useState } from 'react';
import { Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, ButtonGroup } from '@mui/material';
import axios from 'axios';
import apiurl from '../../apiurl';
import CategoryAdapter from './CategoryAdapter/CategoryAdapter';

const Categories = () => {
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [add, setAdd] = useState(false);
  const [addcat, setAddCat] = useState('');

  const loadData = () => {
    axios.get(`${apiurl}/category`)
      .then((res) => {
        console.log(res.data.response.data);
        setData(res.data.response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);

  const addCategory = () => {
    const categoryIndex = data.findIndex(category => category.type === type);
    if (categoryIndex !== -1) {
      const category = data[categoryIndex];
      const updatedCategory = {
        ...category,
        cats: [
          ...category.cats,
          { key: addcat.toLowerCase().trim(), display: addcat }
        ]
      };
      const updatedData = [...data];
      updatedData[categoryIndex] = updatedCategory;

      axios.put(`${apiurl}/category/${category.id}`, updatedCategory)
        .then((res) => {
          console.log(res.data);
          loadData();
          setAdd(false);
        })
        .catch((err) => console.log(err));
    }

    setAddCat("")
  };

  return (
    <div>
      <h1 className='text-center text-blue-600 uppercase text-xl'>Create or delete categories</h1>

      <Paper variant="outlined" style={{ margin: 5, marginTop: 20 }}>
        <div className='text-center'>
          <p className='text-blue-800 text-xl uppercase'>Blog categories</p>
          <div className='my-2'>
            {data &&
              data
                .filter((category) => category.type === 'blog')
                .map((category) =>
                  category.cats.map((cat, i) => (
                    <CategoryAdapter
                      obj={cat}
                      data={category.cats}
                      id={category.id}
                      ind={i}
                      mainid={category.id}
                      loadData={loadData}
                      key={i}
                    />
                  ))
                )}
          </div>
          <Button variant="contained" color="primary" onClick={() => { setType('blog'); setAdd(true) }}>
            Add Category
          </Button>
        </div>
      </Paper>

      <Paper variant="outlined" style={{ margin: 5, marginTop: 20 }}>
        <div className='text-center'>
          <p className='text-blue-800 text-xl uppercase'>Product categories</p>
          <div className='my-2'>
            {data &&
              data
                .filter((category) => category.type === 'product')
                .map((category) =>
                  category.cats.map((cat, i) => (
                    <CategoryAdapter
                      obj={cat}
                      data={category.cats}
                      id={category.id}
                      ind={i}
                      mainid={category.id}
                      loadData={loadData}
                      key={i}
                    />
                  ))
                )}
          </div>
          <Button variant="contained" color="primary" onClick={() => { setType('product'); setAdd(true) }}>
            Add Category
          </Button>
        </div>
      </Paper>

      <Dialog open={add}>
        <DialogTitle>
          Selected Type: {type}
        </DialogTitle>
        <DialogContent>
          <div className='p-1'>
            <TextField
              label="Category"
              value={addcat}
              onChange={(e) => setAddCat(e.target.value)}
              fullWidth
              variant='outlined'
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained" aria-label="">
            <Button color="success" disabled={!addcat} onClick={addCategory}>Add</Button>
            <Button color="secondary" onClick={() => setAdd(false)}>Close</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Categories;
