import { Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import React, { useState } from 'react'

const PayPalCard = ({obj}) => {
    const [open, setopen] = useState(false)
   
    
  return (
    <div className='py-2'>
        <Paper>
            <div className='p-3'>
                <p className='text-gray-600 text-right'>Order ID: {obj.orderID}</p>
                <p className='text-gray-600 text-right'>Date: {obj.time}</p>
                <p className='text-theme text-red-600 text-xl text-right'>Total: {obj.total}$</p>
                
                
                <p className='text-blue-600 mb-3 uppercase font-bold text-xl'>Client</p>
                <p className='text-blue-600'>Full Name: <span className='text-gray-600'>{obj.fullName}</span></p>
                <p className='text-blue-600'>Email: <span className='text-gray-600'>{obj.email}</span></p>
                <p className='text-blue-600'>Phone: <span className='text-gray-600'>{obj.phone}</span></p>
                <p className='text-blue-600'>Address: <span className='text-gray-600'>{obj.address}</span></p>
                <p className='text-blue-600'>City: <span className='text-gray-600'>{obj.city}</span></p>
                <p className='text-blue-600 my-3 uppercase font-bold text-xl'>Payment Method</p>
                <p className='text-blue-600'>Method: <span className='text-gray-600'>{obj.method}</span></p>
                <p className='text-blue-600'>Status: <span className='text-gray-600'>{obj.status ? 'Paid':'Not Paid'}</span></p>
                <p className='text-blue-600 my-3 uppercase font-bold text-xl'>Paypal Info</p>
                <p className='text-blue-600'>Paypal Name: <span className='text-gray-600'>{obj.paypalName}</span></p>
                <p className='text-blue-600'>Paypal Email: <span className='text-gray-600'>{obj.paypalEmail}</span></p>
                <p className='text-blue-600'>Paypal ID: <span className='text-gray-600'>{obj.paypalID}</span></p>
                <center>
                    <Button variant="contained" color="primary" onClick={e=>setopen(true)}>
                      Show Products
                    </Button>
                </center>
            </div>
            <Dialog open={open} onClose={e=> setopen(false)}>
              <DialogTitle>
                Ordered Products
              </DialogTitle>
              <DialogContent>
                  {obj.products.map((v,i)=> <div className='flex items-center mt-3'>
                    <img src={v.thumb} alt={v.name} className="h-auto w-20"/>
                    <div className='flex-1 ml-2'>
                        <p className='text-xl'>{v.name}</p>
                        <p className='text-md text-red-600'>{v.price}$</p>
                        <p className='text-sm'>Qty: {v.amount}</p>
                    </div>
                  </div>)}
              </DialogContent>
              <DialogActions>
                <Button onClick={e=> setopen(false)} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </Paper>
    </div>
  )
}

export default PayPalCard