// UploadedAdapter.js
import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import apiurl from "../../../../apiurl";

const UploadedAdapter = ({ data, loadData }) => {
  const [del, setDel] = useState(false);
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(data.title);
  const [link, setLink] = useState(data.link);
  const [vidthumb, setVidthumb] = useState(data.thumbnail);
  const [vpub, setVpub] = useState(data.privacy ? "public" : "private");
  const [chapters, setChapters] = useState(data.chapters || []);
  const [cats, setCats] = useState([]);
  const [selectedCat, setSelectedCat] = useState(data.category);

  const deleteVideo = () => {
    axios
      .delete(`${apiurl}/video/${data.id}`)
      .then((res) => {
        if (res.status === 200) {
          loadData();
          setDel(false);
        } else {
          alert(res.data.err);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("There is a problem");
      });
  };

  const fetchCategories = () => {
    axios
      .get(`${apiurl}/videocat`)
      .then((res) => {
        if (res.status === 200) {
          setCats(res.data.response.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = () => {
    const updatedData = {
      title: name,
      link,
      thumbnail: vidthumb,
      category: selectedCat,
      privacy: vpub,
      chapters,
    };

    axios
      .put(`${apiurl}/video/${data.id}`, updatedData)
      .then((res) => {
        alert("Video Edited..");
        setEdit(false);
        loadData();
      })
      .catch((err) => {
        console.log(err);
        alert("There is a problem");
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleChapterTitleChange = (index, title) => {
    const updatedChapters = [...chapters];
    updatedChapters[index].title = title;
    setChapters(updatedChapters);
  };

  const handleChapterStartChange = (index, start) => {
    const updatedChapters = [...chapters];
    updatedChapters[index].start = start;
    setChapters(updatedChapters);
  };

  const handleRemoveChapter = (index) => {
    const updatedChapters = [...chapters];
    updatedChapters.splice(index, 1);
    setChapters(updatedChapters);
  };

  const handleAddChapter = () => {
    const newChapter = {
      title: "",
      start: 0,
    };
    setChapters([...chapters, newChapter]);
  };

  return (
    <div className="p-3 rounded-md ring-1 ring-gray-500 m-1">
      <p className="text-blue-600 text-xl py-2">{data.title}</p>
      <p className="pt-2 text-gray-600">Uploaded on: {data.createdAt}</p>
      <p className="text-gray-600">Category: {data.category}</p>
      <p className="pb-2 text-gray-600">Status: {data.privacy}</p>

      <ButtonGroup variant="contained" fullWidth aria-label="">
        <Button color="error" onClick={() => setDel(true)}>
          Delete
        </Button>
        <Button color="primary" onClick={() => setEdit(true)}>
          Edit
        </Button>
      </ButtonGroup>

      <Dialog open={del} onClose={() => setDel(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete this video?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained">
            <Button color="primary" onClick={() => setDel(false)}>
              Cancel
            </Button>
            <Button color="error" onClick={deleteVideo}>
              Delete
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>

      <Dialog open={edit} fullScreen>
        <DialogContent>
          <AppBar position="fixed" color="primary">
            <Toolbar>
              <Typography variant="h6">{data.title}</Typography>
            </Toolbar>
          </AppBar>
          <div className="p-3 ring-1 ring-gray-400 m-2 rounded-lg mt-20">
            <div className="grid grid-cols-2 md:grid-cols-2 mt-10">
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ margin: 5, width: "95%" }}
              />
              <TextField
                label="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                variant="outlined"
                style={{ margin: 5, width: "95%" }}
              />
              <TextField
                label="Thumbnail Url"
                value={vidthumb}
                onChange={(e) => setVidthumb(e.target.value)}
                variant="outlined"
                style={{ margin: 5, width: "95%" }}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCat}
                  label="Category"
                  onChange={(e) => setSelectedCat(e.target.value)}
                >
                  {cats.map((category) => (
                    <MenuItem key={category.key} value={category.key}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <FormControlLabel
              label="Public"
              style={{ margin: 5 }}
              control={
                <Checkbox
                  checked={vpub === "public"}
                  onChange={(e) =>
                    setVpub(e.target.checked ? "public" : "private")
                  }
                />
              }
            />
            <p className="pb-2 text-gray-600">
              Status: {vpub === "public" ? "Public" : "Private"}
            </p>

            <div className="mt-6">
              <h2 className="text-blue-600 text-2xl mb-2">Add Chapters</h2>
              {chapters.map((chapter, index) => (
                <div key={index} className="flex items-center mb-4">
                  <TextField
                    label={`Chapter ${index + 1} Title`}
                    value={chapter.title}
                    onChange={(e) =>
                      handleChapterTitleChange(index, e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    sx={{ marginRight: "1rem" }}
                  />
                  <TextField
                    label={`Chapter ${index + 1} Start`}
                    type="number"
                    value={chapter.start}
                    onChange={(e) =>
                      handleChapterStartChange(index, parseInt(e.target.value))
                    }
                    fullWidth
                    variant="outlined"
                    sx={{ marginRight: "1rem" }}
                  />

                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveChapter(index)}
                  >
                    Remove Chapter
                  </Button>
                </div>
              ))}
              <div className="flex items-center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddChapter}
                  className="mr-4"
                >
                  Add Chapter
                </Button>
              </div>
            </div>

            <br />
            <center>
              <Button variant="contained" color="primary" onClick={handleEdit}>
                Save
              </Button>
              <Button color="error" onClick={() => setEdit(false)}>
                Cancel
              </Button>
            </center>
          </div>
          <IconButton aria-label="" onClick={() => setEdit(false)}>
            <Close className="text-white" />
          </IconButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadedAdapter;
