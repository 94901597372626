import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import TogglePart from './TogglePart/TogglePart'
import { List, Dialog, DialogTitle, DialogContent, DialogActions, ButtonGroup } from '@mui/material'
import apiurl from '../../apiurl'
import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { ReactDOM } from 'react'
import {Editor, EditorState, Modifier} from 'draft-js';
import 'draft-js/dist/Draft.css';
import RichText from '../utils/rich/RichText'

const AboutUsUi = () => {
  const [abot, setabot] = useState('')
  const [user, setuser] = useState('')
  const [testi, settesti] = useState('')
  const [projects, setprojects] = useState('')
  const [charters, setcharters] = useState('')
  const [add, setadd] = useState(false)
  const [addtitle, setaddtitle] = useState('')
  const [addtext, setaddtext] = useState('')
  const [toggle, settoggle] = useState([])
  const [id, setid] = useState('')
  const [edistate, setedistate] = useState(EditorState.createEmpty())
//   const state = edistate.getCurrentContent()
//   const contentStateWithEntity = state.createEntity('LINK', 'MUTABLE', {
//     url: 'http://www.zombo.com',
//   });
//   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
// const contentStateWithLink = Modifier.applyEntity(
//   contentStateWithEntity,

//   entityKey,
// );
// const newEditorState = EditorState.set(edistate, {
//   currentContent: contentStateWithLink,
// });
  const loadData = ()=>{
    axios.get(`${apiurl}/companyDetails`).then((res)=>{
      console.log(res.data.response.data[0])
      const target = res.data.response.data[0]
      setabot(target.aboutUs)
      setuser(target.users)
      settesti(target.testimonials)
      setprojects(target.projectsDone)
      setcharters(target.charters)
      setid(target.id)
    }).catch((err)=>{
      console.log(err)
    }) 
  }
  useEffect(() => {
       loadData()
  }, [])
  
  const updateAbout= ()=>{
    axios.put(`${apiurl}/companyDetails/${id}`,{
      id,
      aboutUs:abot,
    }).then((res)=>{
      alert('Updated..')
      loadData()
    }).catch((err)=>{
      console.log(err)
      alert('there is a problem')
    })
  }

  const updateOther= ()=>{
    axios.put(`${apiurl}/companyDetails/${id}`,{
      id,
      users:user,
      testimonials:testi,
      charters,
      projectsDone:projects,
    }).then((res)=>{
      alert('Updated..')
      loadData()
    }).catch((err)=>{
      console.log(err)
      alert('there is a problem')
    })
  }
  
  return (
    <div className='p-3'>
      <TextField
        label="About"
        value={abot}
        onChange={e => setabot(e.target.value)}
        multiline
        fullWidth
        variant='outlined'
        rows={4}
      />
      {/* <RichText/> */}
      {/* <Editor editorState={edistate} onChange={setedistate} placeholder="Tell me a story"/> */}
      <center>
        <Button variant="contained" color="primary" onClick={updateAbout}>
          Save
        </Button>
      </center>
      <h1 className='text-center text-blue-600 uppercase text-lg font-bold'>Other Datas</h1>
      <div className='grid grid-cols-1 md:grid-cols-2'>
        <div className='p-2'>
          <TextField
            label="Users"
            value={user}
            onChange={e=> setuser(e.target.value)}
            fullWidth
            variant='outlined'            
          />
        </div>
        <div className='p-2'>
          <TextField
            label="Testimonials"
            value={testi}
            onChange={e=> settesti(e.target.value)}
            fullWidth
            variant='outlined'            
          />
        </div>
        <div className='p-2'>
          <TextField
            label="Projects Done"
            value={projects}
            onChange={e=> setprojects(e.target.value)}
            fullWidth
            variant='outlined'            
          />
        </div>
        <div className='p-2'>
          <TextField
            label="Charters"
            value={charters}
            onChange={e=> setcharters(e.target.value)}
            fullWidth
            variant='outlined'            
          />
        </div>
      </div>
      <center>
        <Button variant="contained" color="primary" onClick={updateOther}>
          Save Changes
        </Button>
      </center>
      {/* <div className='mt-5'>
        <h1 className='text-center uppercase text-lg text-blue-600 font-bold'>Toogle part</h1>
        <List>
          {toggle.map((v,i)=> <TogglePart obj={v} id={id} ind={i} toggle={toggle} loadData={loadData}/>)}
        </List>
        <center>
          <Button variant="contained" color="primary" onClick={e=> setadd(true)}>
            Add Element
          </Button>

          <Dialog open={add}>
            <DialogTitle>
              Add Element
            </DialogTitle>
            <DialogContent>
              <div className='p-2'>
                <TextField
                  label="Title"
                  value={addtitle}
                  onChange={e=> setaddtitle(e.target.value)}
                  fullWidth
                  variant='outlined'
                />
                <TextField
                style={{marginTop:20}}
                  label="Description"
                  value={addtext}
                  onChange={e=> setaddtext(e.target.value)}
                  fullWidth
                  variant='outlined'
                  multiline
                  rows={4}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonGroup variant="contained">
                <Button color="primary" onClick={updateToggle}>Save</Button>
                <Button color="secondary" onClick={e=> setadd(false)}>Cancel</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
        </center>
      </div> */}
    </div>
  )
}

export default AboutUsUi