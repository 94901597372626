import { Paper } from '@mui/material'
import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Booking from '../Booking/Booking'
const Dashboard = () => {
  const [selcompo, setselcompo] = useState(<Booking/>)
  return (
    <div>
        <Navbar setselcompo={setselcompo}/>
        <div className='mt-20 mx-5'>
          <Paper variant='outlined' style={{padding:5}}>
            {selcompo}
          </Paper>
        </div>
    </div>
  )
}

export default Dashboard