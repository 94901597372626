import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import UploadVideo from './Upload/UploadVideo'
import Uploaded from './Uploaded/Uploaded'
import VideoCategory from './VideoCategory/VideoCategory'
import VideoUsers from './VideoUsers/VideoUsers'

const Videos = () => {
  const [tab, settab] = useState(0)
  const compArr = [<Uploaded/>,<UploadVideo/>,<VideoCategory/>,<VideoUsers/>]
  const handleChange = (event, newValue) => {
    settab(newValue);
  };
  return (
    <div>
      <Box sx={{width:'100%'}}>
        <center>
        <Box sx={{borderBottom:1, borderColor:'divider'}}>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Uploaded Videos"/>
            <Tab label="Upload Video"/>
            <Tab label="Video Category"/>
            <Tab label="User Controls"/>
          </Tabs>
        </Box>
        </center>
      </Box>
      <div>
        {compArr[tab]}
      </div>
    </div>
  )
}

export default Videos