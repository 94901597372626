import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import apiurl from '../../apiurl'
import axios from 'axios'

const HeaderFooter = () => {
  const [phone, setphone] = useState('')
  const [email, setemail] = useState('')
  const [address, setaddress] = useState('')
  const [hours, sethours] = useState('')
  const [id, setid] = useState(0)


  const getDatas = async () =>{
    await axios.get(`${apiurl}/companyDetails/`).then((res) =>{
      if(res.status === 200){
        console.log(res.data.response.data[0])
        const datas = res.data.response.data[0]
        setphone(datas.phone)
        setemail(datas.email)
        setaddress(datas.address)
        sethours(datas.hours)
        setid(datas.id)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(() => {
    getDatas()
  }, [])
  
  const updatePhoneMail = () =>{
    axios.put(`${apiurl}/companyDetails/${id}`,{
    phone,
     email,
     hours,
     address,
     id
    }).then((res)=>{
      if(res.status === 200){
        getDatas()
        alert('Data Updated')
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <div>
      <h1 className='text-center text-3xl text-blue-800 uppercase'>Company details</h1>
      <p className='text-gray-600 text-center mb-3'>Please click on Save button after changing</p>
      <div className='grid md:grid-cols-2 grid-cols-1'> 
        <div className='p-3'>
          <TextField
            variant='outlined'
            label="Phone Number"
            value={phone}
            fullWidth
            onChange={e => setphone(e.target.value)}
          />
        </div>
        <div className='p-3'>
          <TextField
            label="E-mail"
            value={email}
            onChange={e => setemail(e.target.value)}
            fullWidth
          />
        </div>
        <div className='p-3'>
          <TextField
            label="Company Address"
            value={address}
            fullWidth
            onChange={e => setaddress(e.target.value)}
          />
        </div>
        <div className='p-3'>
          <TextField
            variant='outlined'
            label="Working Hours"
            value={hours}
            fullWidth
            onChange={e => sethours(e.target.value)}
          />
        </div>
      </div>
      <center>
        <Button onClick={updatePhoneMail} variant="contained" color="primary">
          Save Changes
        </Button>
      </center>

    </div>
  )
}

export default HeaderFooter