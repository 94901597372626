import { Close } from '@mui/icons-material'
import { Button, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Toolbar, Typography, AppBar, TextField, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import apiurl from '../../../apiurl'
import { getAuth } from 'firebase/auth'

const ProCard = ({ obj, loadData, cats }) => {
  const [deleteopen, setdeleteopen] = useState(false)
  const [editopen, seteditopen] = useState(false)
  const [editproname, seteditproname] = useState(obj.name)
  const [editproprice, seteditproprice] = useState(obj.price)
  const [editprodesc, seteditprodesc] = useState(obj.description)
  const [editcat, seteditcat] = useState(obj.category)
  const [salecheck, setsalecheck] = useState(obj.sale)
  const [editthumb] = useState('')
  const [imageUrl, setImageUrl] = useState(obj.imageUrl)


  const deleteProduct = () => {
    axios.delete(`${apiurl}/product/${obj.id}`, {
      headers: {
        'Content-type': 'application/json'
      },
      data: {
        obj
      }
    }).then((res) => {
      if (res.status === 200) {
        setdeleteopen(false)
        loadData()
      }
    })
  }

  const editProduct = async () => {
    if (editthumb) {
      let data = new FormData();
      data.append("avatar", editthumb);
      const response = await axios({
        method: 'post',
        url: 'https://fileapi.byachtservices.com/upload.php',
        data: data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      });
  
      if (response.status === 200) {
        axios.put(`${apiurl}/product/${obj.id}`, {
          Headers: {
            'Content-type': 'application/json'
          },
          id: obj.id,
          name: editproname,
          category: editcat,
          price: editproprice,
          description: editprodesc,
          token: getAuth().currentUser.uid,
          sale: salecheck,
          imageUrl: `https://fileapi.byachtservices.com/image/${response.data.data}`,
        }).then((res) => {
          if (res.status === 200) {
            seteditopen(false);
            loadData();
          }
        }).catch((err) => console.log(err));
      }
    } else {
      axios.put(`${apiurl}/product/${obj.id}`, {
        Headers: {
          'Content-type': 'application/json'
        },
        id: obj.id,
        name: editproname,
        category: editcat,
        price: editproprice,
        description: editprodesc,
        sale: salecheck,
        imageUrl:imageUrl
      }).then((res) => {
        if (res.status === 200) {
          seteditopen(false);
          loadData();
        }
      }).catch((err) => console.log(err));
    }
  };
  
  return (
    <div className='border-2 border-gray-300 rounded-md m-2 flex md:items-baseline items-center md:flex-col'>
      {obj.sale ? <div className='bg-blue-800 -mt-40 md:-mt-5 -ml-3 text-white h-16 w-16 flex items-center justify-center rounded-full absolute'>Sale!</div> : null}
      <div className='flex-1'>
        <img src={obj.imageUrl} alt='Could not load' className='h-50 w-50 p-3' />
      </div>
      <div className='m-2'>
        <h1 className='text-3xl text-theme font-bold'>{obj ? obj.price : null}$ </h1>
        <h1 className='text-black text-xl'>{obj.name}</h1>
        <p className='text-md text-gray-500'>{obj.description.substr(0, 15)}...</p>
        <ButtonGroup style={{ marginTop: 20 }} variant="contained" color="primary" aria-label="">
          <Button onClick={e => seteditopen(true)}>Edit</Button>
          <Button onClick={e => setdeleteopen(true)}>Delete</Button>
        </ButtonGroup>
      </div>

      <Dialog open={deleteopen} onClose={e => setdeleteopen(false)} aria-labelledby="Delete Dialog">
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete the product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct}>Delete</Button>
          <Button onClick={e => setdeleteopen(false)} color="primary" variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={editopen}
        onClose={e => seteditopen(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={e => seteditopen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {obj.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className='p-3'>
          <img src={obj.imageUrl} alt="Could not be found" className='h-40 w-50 p-3' />
          <TextField
                label="Image URL"
                value={imageUrl}
                onChange={e => setImageUrl(e.target.value)}
                fullWidth
              />
          <div className='grid md:grid-cols-2 grid-cols-1 mt-5'>
            <div className='p-2'>
              <TextField
                label="Product Name"
                value={editproname}
                onChange={e => seteditproname(e.target.value)}
                fullWidth
              />
            </div>
            <div className='p-2'>
              <TextField
                label="Product Price"
                value={editproprice}
                onChange={e => seteditproprice(e.target.value)}
                fullWidth
              />
            </div>
            <div className='col-span-2 p-2'>
              <TextField
                fullWidth
                multiline
                label="Product Description"
                value={editprodesc}
                onChange={e => seteditprodesc(e.target.value)}
                rows={4}
              />

              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editcat}
                  label="Category"
                  onChange={(e) => {
                    seteditcat(e.target.value);
                  }}
                >
                  {cats.map((cat, index) => (
                    <MenuItem key={cat.index} value={cat.key}>
                      {cat.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                label="Sale"
                control={
                  <Checkbox
                    value="Sale"
                    checked={salecheck}
                    onClick={e => setsalecheck(!salecheck)}
                    color="primary"
                  />
                }
              />
            </div>
            <div className='col-span-2 flex items-center justify-center'>
              <Button variant="contained" color="primary" onClick={editProduct}>
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ProCard;
