import React, { useEffect, useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import apiurl from "../../apiurl";

const Testimonial = () => {
  const [appopen, setappopen] = useState(false);
  const [decopen, setdecopen] = useState(false);
  const [delopen, setdelopen] = useState(false);
  const [selid, setselid] = useState(0);
  const [testi, settesti] = useState([]);

  const loadDatas = () => {
    axios
      .get(`${apiurl}/testimonialForm`)
      .then((res) => {
        if (res.status === 200) {
          settesti(res.data.response.message.reverse());
        } else {
          alert("Database error happened");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadDatas();
  }, []);

  const approvetesti = () => {
    axios
      .put(`${apiurl}/testimonialForm/${selid}`, {
        id: selid,
        status: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setappopen(false);
          loadDatas();
        }
      })
      .catch((err) => alert("Database error happened"));
  };

  const declinetesti = () => {
    axios
      .put(`${apiurl}/testimonialForm/${selid}`, {
        id: selid,
        status: false,
      })
      .then((res) => {
        if (res.status === 200) {
          setdecopen(false);
          loadDatas();
        }
      })
      .catch((err) => alert("Database error happened"));
  };

  const deleteTestimonial = () => {
    axios
      .delete(`${apiurl}/testimonialForm/${selid}`)
      .then((res) => {
        if (res.status === 200) {
          setdelopen(false);
          loadDatas();
          alert("Testimonial deleted successfully!");
        } else {
          alert("Database error occurred");
        }
      })
      .catch((err) => alert("Database error occurred"));
  };

  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1">
        {testi.map((v, i) => (
          <div
            className="rounded-lg p-3 m-1 ring-1 ring-gray-400 flex flex-col items-center justify-center"
            key={v.id}
          >
            <h1 className="text-center text-black text-xl italic">
              {v.testimonial}
            </h1>
            <p className="text-lg">First Name: {v.firstName}</p>
            <p className="text-lg">Last Name: {v.lastName}</p>
            <p className="text-gray-500">Company: {v.company}</p>
            <p className="text-gray-500">Email: {v.email}</p>
            {v.status ? (
              <h1 className="text-center text-black text-xl">
                Status: Approved
              </h1>
            ) : (
              <h1 className="text-center text-black text-xl">
                Status: Unapproved
              </h1>
            )}
            <ButtonGroup sx={{ marginTop: "10px" }} aria-label="">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setappopen(true);
                  setselid(v.id);
                }}
                sx={{ marginRight: "10px" }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setdecopen(true);
                  setselid(v.id);
                }}
                sx={{ marginRight: "10px" }}
              >
                Decline
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setdelopen(true);
                  setselid(v.id);
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        ))}
      </div>
      <Dialog
        open={appopen}
        onClose={() => setappopen(false)}
        aria-labelledby="Approve Testimonial Dialog"
      >
        <DialogTitle>Are you Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to Approve this for Testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={approvetesti} color="primary" variant="contained">
            Approve
          </Button>
          <Button onClick={() => setappopen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={decopen}
        onClose={() => setdecopen(false)}
        aria-labelledby="Decline Testimonial Dialog"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to decline this for testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={declinetesti} variant="contained" color="primary">
            Decline
          </Button>
          <Button onClick={() => setdecopen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={delopen}
        onClose={() => setdelopen(false)}
        aria-labelledby="Delete Testimonial Dialog"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete this testimonial?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteTestimonial} variant="contained" color="error">
            Delete
          </Button>
          <Button onClick={() => setdelopen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Testimonial;
