import React, { useEffect, useState } from 'react'
// import TextField from '@mui/material/TextField'
// import Button from '@mui/material/Button'
// import Typography from '@mui/material/Typography'
// import Dialog from '@mui/material/Dialog'
// import DialogTitle from '@mui/material/DialogTitle'
// import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import axios from 'axios'
// import WhatOffer from './WhatOffer/WhatOffer'
import apiurl from '../../apiurl'
import Top from './Top/Top'

const HomePageUI = () => {
  const [title, settitle] = useState('')
  const [desc, setdesc] = useState('')
  const [icon, seticon] = useState('')
  const [open, setopen] = useState(false)
  const [elems, setelems] = useState([])
  console.log(elems)

  const loadData = ()=>{
    axios.get(`${apiurl}/companyDetails/`).then((res)=>{
      if(res.status === 200){
        setelems(Object.values(res.data.response.data))
        
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(() => {
    loadData()
  }, [])
  
  const uploadWork = async ()=>{
    setopen(true)
    let data = new FormData()
    data.append("avatar",icon)
    const response = await axios({
      method: 'post',
      url: 'https://fileapi.byachtservices.com/upload.php',
      data: data,
      headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
   });
   if(response.status === 200){
     axios.post(`${apiurl}/companyDetails/`,{
       title,
       desc,
       icon:`https://fileapi.byachtservices.com/image/${response.data.data}`
     }).then((res)=>{
        setopen(false)
        loadData()
        settitle('')
        setdesc('')
        seticon('')
     }).catch((err)=>{
       alert(err.message)
     })     
   }
   console.log(response)

  }
  return (
    <div>
      <Top/>
      {/* <h1 className='text-center text-blue-800 uppercase text-3xl font-bold'>what we offer section</h1>
      <div className='p-2'>
        <TextField
          label="Title"
          value={title}
          onChange={e => settitle(e.target.value)}
          fullWidth
        />
      </div>
      <div className='p-2'>
        <TextField
          label="Description"
          value={desc}
          onChange={e => setdesc(e.target.value)}
          multiline
          rows={4}
          fullWidth
        />
    </div>
    <center>
      <Typography>{icon ? `Selected Icon: ${icon.name}` : null}</Typography>
      <Button variant="contained" color="primary" component="label">
        <input type="file" hidden onChange={e=> seticon(e.target.files[0])}/>Select an Icon
      </Button><br/>
      <Button onClick={uploadWork} variant="contained" className='mt-5' color="primary" disabled={!title || !desc || !icon} style={{marginTop:20}}>
        Upload
      </Button>
    </center>

    <Dialog open={open}  aria-labelledby="What We Do">
      <DialogTitle>
        Uploading...
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          The icon is uploading
        </DialogContentText>
      </DialogContent>
    </Dialog>
    <div className='grid grid-cols-1 md:grid-cols-4'>
      {elems.map((v,i)=> <WhatOffer obj={v} loadData={loadData}/>)}
    </div> */}
  </div>
  )
}

export default HomePageUI